import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getDisabledDate,
  getHospitalPayer,
  getSlotTime,
  otpRequest,
  validateOTP,
} from "../../../actions";
import {
  IconArrowGreen,
  IconDummyAvatar,
  IconDummyPlace,
} from "../../../assets/img/icon";
import Button from "../../../components/Button";
import ModalLogin from "../../../components/Modal/LoginV2";
import {
  wordingDoctorSchedule,
  wordingHopitalType,
} from "../../../constants/wording";
import { formatRupiah } from "../../../helpers/formatDigit";
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
  storeLocalStorageItem,
} from "../../../helpers/localStorage";
import { formatDateAsData, isNumber } from "../../../helpers/utils";
import Layout from "../Components/Layout";
import LayoutV2 from "../Components/LayoutV2";
import "./index.scss";

moment.locale("id");

const Schedule = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [SpanPhone, setSpanPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(62);
  const [TimerOnGoing, setTimerOnGoing] = useState(false);
  const [InputForm, setInputForm] = useState(!false);
  const [OtpForm, setOtpForm] = useState(false);
  const [wrongCode, setWrongCode] = useState(true);
  const [timer, setTimer] = useState("00:00");
  const Ref = useRef(null);
  const [StateNotLogin, setStateNotLogin] = useState(true);
  const [StateLogin, setStateLogin] = useState(false);
  const [Otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [remainingTime, setRemainingTime] = useState("00:00");

  const [selectedDay, setSelectedDay] = useState(null);
  const [disableDate, setDisabledDate] = useState();
  const [enabledDate, setEnabledDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [scheduleSlotIndex, setScheduleSlotIndex] = useState(-1);
  const [timeSlotContainer, settimeSlotContainer] = useState(null);
  const [scheduleSlotContainer, setScheduleSlotContainer] = useState(null);
  const [initScheduleSlotContainer, setInitScheduleSlotContainer] =
    useState(true);
  const [dateScheduleData, setdateScheduleData] = useState(null);
  const [consultationTypeData, setconsultationTypeData] = useState(null);
  const [timeSlotData, settimeSlotData] = useState(null);
  const [checkedRadioButton, setCheckedRdioButton] = useState("");
  const [payerBPJS, setPayerBPJS] = useState([]);
  const [payerNonBPJS, setPayerNonBPJS] = useState([]);
  const [mappingConsultationType, setMappingConsultationType] = useState([]);

  const { payerData } = useSelector((state) => state.paymentMethod);
  const insurance = useSelector((state) => state.paymentMethod);
  const doctorSchedule = useSelector((state) => state.doctorSchedule);
  const curYear = moment().year();
  const navigate = useHistory();

  const dispatch = useDispatch();
  const { state } = useLocation();

  const idLocale = {
    // months list by order
    months: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
  
    // week days by order
    weekDays: [
      {
        name: "Minggu", // used for accessibility 
        short: "Min", // displayed at the top of days rows
        isWeekend: true, // is it a formal weekend or not?
      },
      {
        name: "Senin",
        short: "Sen",
      },
      {
        name: "Selasa",
        short: "Sel",
      },
      {
        name: "Rabu",
        short: "Rab",
      },
      {
        name: "Kamis",
        short: "Kam",
      },
      {
        name: "Jum'at",
        short: "Jum",
      },
      {
        name: "Saturday",
        short: "S",
        isWeekend: true,
      },
    ],
  
    // just play around with this number between 0 and 6
    weekStartingIndex: 0,
  
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },
  
    // return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },
  
    // return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },
  
    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },
  
    // texts in the date picker
    nextMonth: 'Bulan Berikutnya',
    previousMonth: 'Bulan Sebelumnya',
    openMonthSelector: 'Buka Pilih Bulan',
    openYearSelector: 'Buka Pilih Tahun',
    closeMonthSelector: 'Tutup Pilih Bulan',
    closeYearSelector: 'Tutup Pilih Tahun',
    defaultPlaceholder: 'Pilih...',
  
    // for input range value
    from: 'dari',
    to: 'sampai',
  
    // is your language rtl or ltr?
    isRtl: false,
  }

  // useEffect(() => {
  //   const lastPath = retrieveLocalStorageItem("lastPath");
  //   const selectedDate = retrieveLocalStorageItem("selectedDate");
  //   const selectedConsultationType = retrieveLocalStorageItem(
  //     "selectedConsultationType"
  //   );
  //   const selectedTime = retrieveLocalStorageItem("selectedTime");

  //   setCheckedRdioButton(selectedConsultationType || null);
  //   setSelectedDay(selectedDate || null);
  //   settimeSlotData(selectedTime || null);
  //   settimeSlotContainer(true);

  //   if (lastPath) {
  //     window.location = lastPath;
  //     removeLocalStorageItem("lastPath");
  //   }
  // }, []);

  useEffect(() => {
    if (Otp) {
      if (Otp.length == 6) {
        validateOtp();
      }
    }
  }, [Otp]);

  const closeModal = () => {
    setPhone("");
    setCountryCode(62);
    return setShowModal(false);
  };

  const rerequestOtp = () => {
    setTimerOnGoing(false);
    clearTimer(getDeadTime());
  };

  const getTimeRemainingAttemp = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimerAttemp = (e) => {
    let { total, minutes, seconds } = getTimeRemainingAttemp(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setRemainingTime(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimerAttemp = (e) => {
    setRemainingTime("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimerAttemp(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTimeAttemp = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const requestOtp = () => {
    setWrongCode(true);
    if (phone) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("country_code", `${countryCode}`);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("urlWeb", window.location.href);

      setSpanPhone(`+${countryCode}${phone}`);
      clearTimer(getDeadTime());

      dispatch(otpRequest(form))
        .then((response) => {
          if (response.status == 200) {
            setStatus(response.status);
            setMessage(response.message);
            setOtpForm(!false);
            setInputForm(false);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          } else {
            clearTimerAttemp(getDeadTimeAttemp());
            setOtpForm(!false);
            setInputForm(false);
            setStatus(response.status);
            setMessage(response.message);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    }
  };

  const clearTimer = (e) => {
    setTimer("00:60");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const validateOtp = (e) => {
    if (Otp) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("otp", Otp);

      dispatch(validateOTP(form))
        .then((res) => {
          if (res.status == 200) {
            setStateLogin(true);
            setStateNotLogin(false);
            setOtpForm(!false);
            setInputForm(false);
            closeModal();
            setWrongCode(true);
          } else {
            setWrongCode(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    }
  };

  const handleSetPhone = (num) => {
    if (num) {
      if (isNumber(num)) {
        setPhone(num);
      }
    } else {
      setPhone("");
    }
  };

  const handleDoctorSearch = () => {
    const params = {
      ...state[0],
      hospital_id: state[0]?.search_id_hospital?.length
        ? [state[0]?.search_id_hospital[0]]
        : [],
      name_doctor: state[0]?.name_doctor || null,
      specialization_id: state[0]?.search_specialization_id || null,
      hospital_group_id: state[0]?.hospital_group_id || null,
      schedule_date: state[0]?.schedule_date || null,
      gender: state[0]?.search_gender || null,
      consultation: state[0]?.search_consultation_type || null,
      filterName: state[0]?.filterName,
      filterKlinik: state[0]?.filterKlinik,
      filterSpecialization: state[0]?.filterSpecialization,
      filterGender: state[0]?.filterGender,
      filterConsultation: state[0]?.filterConsultation,
    };

    let path = "";

    if (state[0].serviceType === "JT") {
      path = "/list-v2/janji-temu";
    }

    navigate.push(path, [params]);
  };

  const handleRadioButton = (e) => {
    storeLocalStorageItem({
      storageKey: "selectedConsultationType",
      storageValue: e.target.value,
    });
    setCheckedRdioButton(e.target.value);
  };

  const resetRadio = () => {
    if (selectedDay == null) {
      setScheduleSlotContainer(null);
      setInitScheduleSlotContainer(true);
    } else {
      setScheduleSlotContainer(true);
      setInitScheduleSlotContainer(null);
    }
  };

  const handleSubmit = () => {
    switch (state[0].serviceType) {
      case "TL":
        fillScheduleData();
        break;
      case "JT":
        fillScheduleData();
        break;
      case "PM":
        fillProcedureData();
      default:
        break;
    }
  };

  const fillScheduleData = () => {
    if (dateScheduleData == null) {
      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Tanggal Konsultasi",
      });
    } else if (consultationTypeData == null) {
      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Tipe Pembayaran Konsultasi",
      });
    } else if (timeSlotData == null) {
      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Jam Konsultasi",
      });
    } else if (
      dateScheduleData != null &&
      consultationTypeData != null &&
      timeSlotData != null
    ) {
      var timeSlotSplit = timeSlotData.toString().split("-");
      const payloadScheduleData = {
        schedule_id:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex]["schedule_id"],
        doctor_schedule_id:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex]
            ?.doctor_schedule_id,
        consultation_type:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex][
            "consultation_type"
          ],
        consultation_name:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex][
            "consultation_name"
          ],
        scheduleType:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex]["schedule_type"],
        date: dateScheduleData,
        from_time: timeSlotSplit[0],
        to_time: timeSlotSplit[1],
        note: "",
        doctor_id: state[0].doctor_id,
        doctor_hospital_id: state[0].doctor_hospital_id,
        specialization_id: state[0].specialization_id,
        poli_id: state[0].poli_id,
        doctorName: state[0].doctor_name,
        hospital_id: state[0].hospital_id,
        hospitalName: state[0].hospital_name,
        paymentMethodtype: consultationTypeData,
        hospitalType: state[0].hospital_type,
        complaints: "",
        timezone: state[0].timezone,
        serviceType: state[0].serviceType,
        hospitalPayer:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex][
            "consultation_type"
          ] === 2
            ? payerBPJS
            : payerNonBPJS,
      };

      if (localStorage.accessToken) {
        let params = {};
        params["name_doctor"] = state[0].name_doctor;
        params["search_id_hospital"] = state[0].search_id_hospital?.length
          ? [state[0].search_id_hospital[0]]
          : [];
        params["search_specialization_id"] =
          state[0].search_specialization_id || null;
        params["search_gender"] = state[0].search_gender || null;
        params["search_consultation_type"] =
          state[0].search_consultation_type || null;
        params["hospital_group_id"] = state[0].hospital_group_id || null;
        params["doctor_imageurl"] = state[0].doctor_imageurl || null;
        params["doctor_name"] = state[0].doctor_name || null;
        params["specialization_name"] = state[0].specialization_name || null;
        params["hospital_image_url"] = state[0].hospital_image_url || null;
        params["hospital_name"] = state[0].hospital_name || null;
        params["hospital_address"] = state[0].hospital_address || null;
        params["payers"] = payerData || null;
        params["serviceFee"] = state[0].serviceFee || 0;
        params["regularChecked"] = state[0].regularChecked;
        params["bpjsChecked"] = state[0].bpjsChecked;
        params["filterName"] = state[0]?.filterName;
        params["filterKlinik"] = state[0]?.filterKlinik;
        params["filterSpecialization"] = state[0]?.filterSpecialization;
        params["filterGender"] = state[0]?.filterGender;
        params["filterConsultation"] = state[0]?.filterConsultation;

        removeLocalStorageItem("selectedDate");
        removeLocalStorageItem("selectedConsultationType");
        removeLocalStorageItem("selectedTime");

        return navigate.push(`/patient-v3`, {
          appointmentData: { ...payloadScheduleData, ...params },
        });
      }

      return setShowModal(true);
    }
  };

  const fillProcedureData = () => {
    var result = Object.entries(selectedDay);
    const strDate =
      result[0][1].toString().length == 1
        ? "0" + result[0][1].toString()
        : result[0][1].toString();
    const strMonth =
      result[1][1].toString().length == 1
        ? "0" + result[1][1].toString()
        : result[1][1].toString();

    const selectedDate =
      strDate + "-" + strMonth + "-" + result[2][1].toString();

    if (!dateScheduleData) {
      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Tanggal Konsultasi",
      });
    } else if (dateScheduleData) {
      const payloadScheduleData = {
        schedule_id: null,
        doctor_schedule_id: null,
        consultation_type: 0,
        consultation_name: "",
        scheduleType: 0,
        note: "",
        doctor_id: 0,
        doctor_hospital_id: state[0].doctor_hospital_id,
        specialization_id: 0,
        poli_id: 0,
        doctorName: "",
        hospital_id: state[0].hospital_id,
        hospitalName: state[0].hospital_name,
        paymentMethodtype: 0,
        hospitalType: state[0].hospital_type,
        complaints: "",
        timezone: state[0].timezone,
        serviceType: state[0].serviceType,
        hospitalPayer: payerNonBPJS,
        mcuProceduresId: state[0].mcuProceduresId,
      };
      if (localStorage.accessToken) {
        let params = {};
        params["name_doctor"] = "";
        params["search_id_hospital"] = state[0].search_id_hospital?.length
          ? [state[0].search_id_hospital[0]]
          : [];
        params["search_specialization_id"] = 0;
        params["search_gender"] = state[0].search_gender || null;
        params["search_consultation_type"] = 0;
        params["hospital_group_id"] = state[0].hospital_group_id || null;
        params["doctor_imageurl"] = state[0].doctor_imageurl || null;
        params["doctor_name"] = state[0].doctor_name || null;
        params["specialization_name"] = state[0].specialization_name || null;
        params["hospital_image_url"] = state[0].hospital_image_url || null;
        params["hospital_name"] = state[0].hospital_name || null;
        params["hospital_address"] = state[0].hospital_address || null;
        params["payers"] = payerData || null;
        params["date"] = selectedDate;
        params["isPrepaid"] = state[0].isPrepaid || null;
        params["mcuProceduresId"] = state[0].mcuProceduresId || null;
        params["serviceFee"] = state[0].serviceFee || 0;

        removeLocalStorageItem("selectedDate");
        removeLocalStorageItem("selectedConsultationType");
        removeLocalStorageItem("selectedTime");

        return navigate.push(`/patient-v3`, {
          appointmentData: { ...payloadScheduleData, ...params },
        });
      }
      return setShowModal(true);
    }
  };

  const fillTimeSlot = async (e) => {
    storeLocalStorageItem({ storageKey: "selectedTime", storageValue: e });
    settimeSlotData(e);
  };

  const setDataPM = async () => {
    storeLocalStorageItem({
      storageKey: "selectedTime",
      storageValue: "00:00-00:00",
    });
    settimeSlotData("00:00-00:00");
    setScheduleSlotIndex(1);
    setconsultationTypeData(3);
  };

  const getCalendarDisabled = async () => {
    const payload = {
      month: 0,
      doctor_hospital_id: state[0].doctor_hospital_id,
      hospital_id: state[0].hospital_id,
      active_date: 0,
      split_date: 1,
      year: curYear,
      serviceType: state[0].serviceType,
    };

    const results = await dispatch(getDisabledDate(payload));
    setDisabledDate(results ? results.deactiveDate : []);
    setEnabledDate(results ? results.activeDate : []);
    setMaxDate(results ? results.lastDate : []);
  };

  const getSlot = async (params) => {
    if (params != null) {
      var result = Object.entries(selectedDay);

      setScheduleSlotIndex(-1);
      settimeSlotContainer(null);
      if (state[0].serviceType !== "PM") {
        setconsultationTypeData(null);
      }

      const strDate =
        result[0][1].toString().length == 1
          ? "0" + result[0][1].toString()
          : result[0][1].toString();
      const strMonth =
        result[1][1].toString().length == 1
          ? "0" + result[1][1].toString()
          : result[1][1].toString();

      const selectedDate =
        strDate + "-" + strMonth + "-" + result[2][1].toString();

      const slotPayload = {
        doctor_id: state[0].doctor_id,
        doctor_hospital_id: state[0].doctor_hospital_id,
        date: selectedDate,
        serviceType: state[0].serviceType,
        detail_slot: 0,
      };
      dispatch(getSlotTime(slotPayload));
      setdateScheduleData(selectedDate);
    }
  };

  useEffect(() => {
    if (doctorSchedule.doctorScheduleData.length > 0) {
      var data = doctorSchedule.doctorScheduleData;

      const result = Object.entries(
        data.reduce(
          (
            datas,
            {
              consultation_type,
              consultation_name,
              schedule_id,
              fromTime,
              endTime,
            }
          ) =>
            Object.assign(datas, {
              [consultation_type]: [
                ...(datas[consultation_type] || []),
                { consultation_name, schedule_id, fromTime, endTime },
              ],
            }),
          {}
        )
      ).map(([key, value]) => ({ consultation_type: key, children: value }));

      setMappingConsultationType(result);
    }
  }, [doctorSchedule.doctorScheduleData]);

  const getConsultationType = async (params) => {
    if (dateScheduleData == null) {
      return Swal.fire({
        icon: "error",
        text: "Harap Memilih Tanggal Konsultasi",
      });
    }
    const slotList = doctorSchedule.doctorScheduleData;

    settimeSlotContainer(true);
    if (slotList.length > 0) {
      const consultationIdIndex = slotList.findIndex(
        (p) => p.consultation_type == params
      );
      setScheduleSlotIndex(consultationIdIndex);
      setconsultationTypeData(params);
    } else {
      setScheduleSlotIndex(-1);
    }
  };

  useEffect(() => {
    getCalendarDisabled();
    dispatch(getHospitalPayer(state[0].hospital_id)).then((response) => {
      const cekBPJSPayer = response.data.payerClinics
        ? response.data.payerClinics.filter((x) => x.prefix == "BP")
        : [];
      const cekNonBPJSPayer = response.data.payerClinics
        ? response.data.payerClinics.filter((x) => x.prefix != "BP")
        : [];
      setPayerBPJS(cekBPJSPayer);
      setPayerNonBPJS(
        state[0]?.serviceType === "PM"
          ? response.data.payerClinics.filter((x) => x.prefix === "PR")
          : cekNonBPJSPayer
      );
    });
  }, []);

  useEffect(() => {
    resetRadio();
  }, [dateScheduleData]);

  useEffect(() => {
    getSlot(selectedDay);
  }, [selectedDay]);

  const onChangeCalendar = (value) => {
    storeLocalStorageItem({ storageKey: "selectedDate", storageValue: value });
    setSelectedDay(value);
    setCheckedRdioButton(null);
    settimeSlotData(null);
    removeLocalStorageItem("selectedConsultationType");
    removeLocalStorageItem("selectedTime");
    if (state?.length > 0 && state[0]?.serviceType === "PM") {
      setDataPM();
    }
  };

  const validateDate = (endTime) => {
    if (selectedDay) {
      const newEndTime = parseInt(endTime.replace(":", ""));
      const today = new Date();
      const { day, month, year } = selectedDay;
      const endTimeNow = `${today.getHours()}:${
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
      }`;
      const newEndTimeNow = parseInt(endTimeNow.replace(":", ""));
      return (
        formatDateAsData(today) ===
          formatDateAsData(new Date(`${year}-${month}-${day}`)) &&
        newEndTime < newEndTimeNow
      );
    }
  };

  const getOperatingSystem = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("Win") !== -1) {
      return "https://play.google.com/store/apps/details?id=app.bpjs.mobile&hl=id&gl=US";
    } else if (userAgent.indexOf("Mac") !== -1) {
      return "https://apps.apple.com/id/app/mobile-jkn/id1237601115";
    } else if (userAgent.indexOf("Linux") !== -1) {
      return "https://play.google.com/store/apps/details?id=app.bpjs.mobile&hl=id&gl=US";
    } else if (userAgent.indexOf("Android") !== -1) {
      return "https://play.google.com/store/apps/details?id=app.bpjs.mobile&hl=id&gl=US";
    } else if (
      userAgent.indexOf("iOS") !== -1 ||
      userAgent.indexOf("iPad") !== -1 ||
      userAgent.indexOf("iPhone") !== -1
    ) {
      return "https://apps.apple.com/id/app/mobile-jkn/id1237601115";
    } else {
      return "https://play.google.com/store/apps/details?id=app.bpjs.mobile&hl=id&gl=US";
    }
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="fix-nav">
        <Col>
          <div className="d-flex align-items-center">
            <img
              className="cursor-point"
              src={IconArrowGreen}
              onClick={handleDoctorSearch}
            />
            &nbsp;&nbsp;
            <span
              className="font-s-18 font-w-700 cursor-point"
              onClick={handleDoctorSearch}
            >
              Janji Temu - Jadwal Dokter
            </span>
          </div>
        </Col>
      </Row>

      <Row className="margin-t-66 margin-b-16 text-center">
        <Col xs={12}>
          <span className="font-s-14 font-w-400">Langkah 1 Dari 3</span>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center margin-b-16">
        <Col xs={12} lg={10}>
          <div className="card-patient-v3">
            <Row>
              <Col className="d-flex" xs={10} lg={6}>
                <div>
                  <img
                    className="margin-r-8"
                    src={IconDummyAvatar}
                    alt="avatar"
                    height="40px"
                  ></img>
                </div>

                <div className="d-flex flex-column">
                  <span className="font-s-14 font-w-700">
                    {state[0].doctor_name}
                  </span>
                  <span className="font-s-14 font-w-400">
                    {state[0].specialization_name}
                  </span>
                </div>
              </Col>

              <Col
                className="d-flex justify-content-end align-items-center text-right"
                xs={2}
                lg={6}
              >
                <img
                  className="cursor-point"
                  src="/img/icon/ic_view.svg"
                  onClick={() => {
                    state[0]["from"] = "schedule";

                    navigate.push("/doctor-detail-v2", state);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <hr />
              </Col>
            </Row>

            <Row className="margin-b-8">
              <Col>
                <span className="font-s-14 font-w-400">
                  {state[0].hospital_name}
                </span>
              </Col>
            </Row>

            <Row className="margin-b-16">
              <Col xs={6} lg={3}>
                <img
                  className="margin-r-8"
                  src={
                    state[0].regularChecked === 1
                      ? "/img/icon/ic_check_green.svg"
                      : "/img/icon/ic_check_gray.svg"
                  }
                />
                <span
                  className={`font-s-14 font-w-400${
                    state[0].regularChecked === 1 ? "" : " cl-grey"
                  }`}
                >
                  Reguler
                </span>
              </Col>

              <Col xs={6} lg={3}>
                <img
                  className="margin-r-8"
                  src={
                    state[0].bpjsChecked === 1
                      ? "/img/icon/ic_check_green.svg"
                      : "/img/icon/ic_check_gray.svg"
                  }
                />
                <span
                  className={`font-s-14 font-w-400${
                    state[0].bpjsChecked === 1 ? "" : " cl-grey"
                  }`}
                >
                  Melayani BPJS
                </span>
              </Col>
            </Row>

            <Row className="margin-b-16">
              <Col xs={12} lg={6}>
                <Row className="margin-b-12">
                  <Col>
                    <span className="font-s-16 font-w-700">Pilih Jadwal</span>
                  </Col>
                </Row>

                <Row className="margin-b-8">
                  <Col>
                    <div className="calender-card">
                      {(state?.length > 0 && state[0]?.serviceType === "TL") ||
                      state[0]?.serviceType === "JT" ? (
                        <Calendar
                          locale={idLocale}
                          value={selectedDay}
                          onChange={(value) => onChangeCalendar(value)}
                          colorPrimary="#007DFF"
                          calendarClassName="react-calendar calender-container"
                          disabledDays={disableDate}
                          minimumDate={utils().getToday()}
                          maximumDate={maxDate}
                          customDaysClassName={enabledDate}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="margin-b-16">
                  <Col className="d-flex align-items-center">
                    <div className="legend-avail"></div>
                    <span className="font-s-14 font-w-400 margin-r-8">
                      Tersedia
                    </span>

                    <div className="legend-full"></div>
                    <span className="font-s-14 font-w-400">Habis Terpesan</span>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={6}>
                <Row className="margin-b-12">
                  <Col>
                    <span className="font-s-16 font-w-700">Pilih Waktu</span>
                  </Col>
                </Row>

                {!selectedDay && (
                  <Row className="d-flex justify-content-center">
                    <Col xs={9}>
                      <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <span className="font-s-14 font-w-400 margin-b-8">
                          Pilih tanggal yang tersedia terlebih dahulu untuk
                          memilih jam
                        </span>

                        <img src="/img/icon/icon-watch-grey.svg" />
                      </div>
                    </Col>
                  </Row>
                )}

                <Row className="content-schedule-doctor-v2 margin-b-8">
                  <Col>
                    {scheduleSlotContainer && (
                      <div className="init-app-card ">
                        {state?.length > 0 && state[0]?.serviceType !== "PM" ? (
                          <div className="card-txt">
                            <div className="card-txt">
                              <span className="font-s-14 font-w-600">
                                Tipe Konsultasi
                              </span>
                            </div>

                            <Row className="margin-b-12">
                              {mappingConsultationType.length > 0 ? (
                                mappingConsultationType
                                  ?.filter((e) => {
                                    if (state[0]?.serviceType === "TL") {
                                      if (+e?.consultation_type === 3) {
                                        return e;
                                      }
                                    } else {
                                      if (+e?.consultation_type !== 3) {
                                        return e;
                                      }
                                    }
                                  })
                                  ?.map((data, i) =>
                                    data.consultation_type == 1 &&
                                    payerNonBPJS.length > 0 ? (
                                      <Col lg={6} key={data.consultation_type}>
                                        <input
                                          type="radio"
                                          className={
                                            data.availabilty == false
                                              ? "fullbook"
                                              : "hidden"
                                          }
                                          name="cb"
                                          id={"cb1" + data.consultation_type}
                                          onClick={(e) =>
                                            getConsultationType(
                                              data.consultation_type
                                            )
                                          }
                                          value={data.consultation_type}
                                          onChange={handleRadioButton}
                                          checked={
                                            checkedRadioButton ==
                                            data.consultation_type
                                          }
                                        />

                                        <label
                                          htmlFor={
                                            "cb1" + data.consultation_type
                                          }
                                        >
                                          {data.children[0].consultation_name}
                                        </label>
                                      </Col>
                                    ) : data.consultation_type == 2 &&
                                      payerBPJS.length > 0 ? (
                                      <Col lg={6} key={data.consultation_type}>
                                        <input
                                          type="radio"
                                          className={
                                            data.availabilty == false
                                              ? "fullbook"
                                              : "hidden"
                                          }
                                          name="cb"
                                          id={"cb1" + data.consultation_type}
                                          onClick={(e) =>
                                            getConsultationType(
                                              data.consultation_type
                                            )
                                          }
                                          value={data.consultation_type}
                                          onChange={handleRadioButton}
                                          checked={
                                            checkedRadioButton ==
                                            data.consultation_type
                                          }
                                        />

                                        <label
                                          htmlFor={
                                            "cb1" + data.consultation_type
                                          }
                                        >
                                          {data.children[0].consultation_name}
                                        </label>
                                      </Col>
                                    ) : data.consultation_type == 3 ? (
                                      <Col lg={6} key={data.consultation_type}>
                                        <input
                                          type="radio"
                                          className={
                                            data.availabilty == false
                                              ? "fullbook"
                                              : "hidden"
                                          }
                                          name="cb"
                                          id={"cb1" + data.consultation_type}
                                          onClick={(e) =>
                                            getConsultationType(
                                              data.consultation_type
                                            )
                                          }
                                          value={data.consultation_type}
                                          onChange={handleRadioButton}
                                          checked={
                                            checkedRadioButton ==
                                            data.consultation_type
                                          }
                                        />

                                        <label
                                          htmlFor={
                                            "cb1" + data.consultation_type
                                          }
                                        >
                                          {data.children[0].consultation_name}
                                        </label>
                                      </Col>
                                    ) : (
                                      <p
                                        className={`text-dark font14 font-italic text-center ${
                                          i ===
                                          mappingConsultationType.length - 1
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        Tidak ada data tipe konsultasi
                                      </p>
                                    )
                                  )
                              ) : (
                                <p className="text-dark font14 font-italic text-center">
                                  Tidak ada data tipe konsultasi
                                </p>
                              )}
                            </Row>
                          </div>
                        ) : (
                          <div className="card-txt">
                            <div className="card-txt">
                              <span className="font-s-14 font-w-600">
                                Jam Operasional
                              </span>

                              <Row className="margin-b-12">
                                <Col xs={12}>
                                  <span className="font-s-14 font-w-400">
                                    Senin s/d Minggu
                                  </span>
                                </Col>
                                <Col xs={12}>
                                  <span className="font-s-14 font-w-400">
                                    Jam 09:00 - 21:00
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}

                        {timeSlotContainer && (
                          <div className="card-txt">
                            <div className="card-txt">
                              <span className="font-s-14 font-w-600">
                                Pilih Waktu
                              </span>
                            </div>

                            <div className="time-slot">
                              <Row>
                                {doctorSchedule.doctorScheduleData[
                                  scheduleSlotIndex
                                ]["slot"] ? (
                                  doctorSchedule.doctorScheduleData[
                                    scheduleSlotIndex
                                  ]["slot"]
                                    ?.filter(
                                      (e) =>
                                        +e.consultation_type ===
                                        +checkedRadioButton
                                    )
                                    .map((data) => (
                                      <Col xs={6} key={data.time}>
                                        <input
                                          type="radio"
                                          className="hidden"
                                          name="ca"
                                          id={data.time}
                                          value={data.time}
                                          onClick={(e) =>
                                            fillTimeSlot(e.target.value)
                                          }
                                          disabled={
                                            data.full ||
                                            validateDate(
                                              data?.time?.split("-")[1]
                                            )
                                          }
                                          checked={timeSlotData == data.time}
                                        />
                                        <label htmlFor={data.time}>
                                          {data.time}
                                        </label>
                                      </Col>
                                    ))
                                ) : (
                                  <div className="card-txt col-12 pt-3 pb-3">
                                    <li key="notfound">
                                      <p>
                                        <strong>Jadwal Tidak Ditemukan</strong>
                                      </p>
                                    </li>
                                  </div>
                                )}
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>

                {checkedRadioButton == 2 && (
                  <Row>
                    <Col>
                      <div className="card-bpjs text-center">
                        <span className="font-s-14 font-w-400">
                          Untuk tipe konsultasi BPJS, silakan melakukan janji
                          temu melalui aplikasi
                        </span>

                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className="margin-r-8 cursor-point"
                            src="/img/icon/icon-jkn.svg"
                            onClick={() => {
                              window.open(
                                "https://play.google.com/store/apps/details?id=app.bpjs.mobile&hl=id&gl=US"
                              );
                            }}
                          />

                          <span
                            className="font-s-14 font-w-700 cursor-point"
                            onClick={() => {
                              let url = getOperatingSystem();

                              window.open(url);
                            }}
                          >
                            mobile JKN
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row className="margin-t-40">
              <Col>
                <Button
                  label="Selanjutnya"
                  type="primary"
                  disable={
                    !dateScheduleData ||
                    !consultationTypeData ||
                    !timeSlotData ||
                    checkedRadioButton == 2
                  }
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <ModalLogin
        InputForm={InputForm}
        TimerOnGoing={TimerOnGoing}
        closeModal={closeModal}
        isShow={showModal}
        phone={phone}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        requestOtp={requestOtp}
        rerequestOtp={rerequestOtp}
        Otp={Otp}
        setOtp={setOtp}
        validateOtp={validateOtp}
        OtpForm={OtpForm}
        SpanPhone={SpanPhone}
        setPhone={handleSetPhone}
        timer={timer}
        message={message}
        remainingTime={remainingTime}
        setInputForm={setInputForm}
        setOtpForm={setOtpForm}
        status={status}
        wrongCode={wrongCode}
      />
    </LayoutV2>
  );
};

export default Schedule;
