import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button as ButtonReact } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getKlinikHeader,
  otpRequest,
  validateOTP,
} from "../../../../../actions";
import { IconArrowWhite } from "../../../../../assets/img/icon";
import Button from "../../../../../components/Button";
import ModalLogin from "../../../../../components/Modal/Login";
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
  storeLocalStorageItem,
} from "../../../../../helpers/localStorage";
import "./index.scss";

const Header = () => {
  /* Router DOM */
  const history = useHistory();
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const [isShow, invokeModal] = React.useState(false);
  const [OtpForm, setOtpForm] = useState(false);
  const [InputForm, setInputForm] = useState(!false);
  const [TimerOnGoing, setTimerOnGoing] = useState(false);
  const [StateNotLogin, setStateNotLogin] = useState(true);
  const [StateLogin, setStateLogin] = useState(false);
  const [timer, setTimer] = useState("00:00");
  let [SpanPhone, setSpanPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [Otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [remainingTime, setRemainingTime] = useState("00:00");
  const [subdomain, setSubDomain] = useState(null);
  const [kliniHeader, setKlinikHeader] = useState([]);
  const [klinikServices, setKlinikServices] = useState([]);
  const dispatch = useDispatch();
  const Ref = useRef(null);

  useEffect(() => {
    var timestampToday = moment().format("X");
    var expiredLocalstorage = localStorage.getItem("expired");

    if (timestampToday > expiredLocalstorage) {
      localStorage.clear();
    }

    const host = window.location.host; // gets the full domain of the app
    const arr = host.split(".");
    // .slice(0, host.includes('core-klinik.aido.id') ? -1 : -2);
    // if (arr.length > 0) {
    //   if (arr[0] == 'core-klinik-fe' || arr[0] == 'localhost:5002') {
    //     getHeaderKlinik('siloam');
    //   } else {
    //     setSubDomain(arr[0]);
    //     getHeaderKlinik(arr[0]);
    //   }
    // } else {
    //   getHeaderKlinik('siloam');
    // }

    if (arr.length > 0) {
      if (arr[0] == "core-klinik-fe" || arr[0] == "localhost:5002") {
        getHeaderKlinik("klinikaido");
      } else {
        setSubDomain(arr[0]);
        getHeaderKlinik(arr[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (auth.headerData) {
      if (auth.headerData.klinik_group_id === null) {
        history.push("/404");
      }
    }
  }, [auth.headerData]);

  const initModal = () => {
    return invokeModal(!false);
  };

  const closeModal = () => {
    setPhone("");
    return invokeModal(false);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const getTimeRemainingAttemp = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const startTimerAttemp = (e) => {
    let { total, minutes, seconds } = getTimeRemainingAttemp(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setRemainingTime(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:60");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const clearTimerAttemp = (e) => {
    setRemainingTime("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimerAttemp(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const getDeadTimeAttemp = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
    clearTimerAttemp(getDeadTimeAttemp());
  }, []);

  useEffect(() => {
    if (Otp) {
      if (Otp.length == 6) {
        validateOtp();
      }
    }
  }, [Otp]);

  useEffect(() => {
    if (auth.loginData) {
      if (auth.loginData.length != 0) {
        const lastPath = retrieveLocalStorageItem("lastPath");
        localStorage.setItem("userId", auth.loginData[0].userId);
        localStorage.setItem("accessToken", auth.loginData[0].accessToken);
        localStorage.setItem("avatar", auth.loginData[0].avatar);
        localStorage.setItem("fullName", auth.loginData[0].fullName);
        localStorage.setItem("firstName", auth.loginData[0].firstName);
        localStorage.setItem("lastName", auth.loginData[0].lastName);
        localStorage.setItem("phone", auth.loginData[0].phone);
        localStorage.setItem("expired", moment().add(23, "hour").format("X"));

        window.location = lastPath;
        removeLocalStorageItem("lastPath");
      }
    }
  }, [auth.loginData]);

  useEffect(() => {
    if (auth.headerData) {
      if (auth.headerData.length != 0) {
        localStorage.setItem("headerData", JSON.stringify(auth.headerData));
        const par = JSON.parse(localStorage.headerData);
        localStorage.setItem("hospitalGrupId", par.klinik_group_id);
        localStorage.setItem("klinikGrupId", par.klinik_group_id);
        localStorage.setItem("klinikLogo", par.klinik_logo);
        localStorage.setItem("klinikTerms", par.klinik_terms);
        localStorage.setItem("klinikSlogan", par.klinik_slogan);
        localStorage.setItem(
          "klinikServices",
          JSON.stringify(par.klinik_services)
        );
        setKlinikServices(par.klinik_services);
        localStorage.setItem("klinikId", par.klinik_id);
        setKlinikHeader(par);
      }
    }
  }, [auth.headerData]);

  const rerequestOtp = () => {
    setTimerOnGoing(false);
    clearTimer(getDeadTime());
  };

  const requestOtp = (e) => {
    if (phone) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("urlWeb", window.location.href);

      setSpanPhone("+62" + phone.toString());
      clearTimer(getDeadTime());

      dispatch(otpRequest(form))
        .then((response) => {
          if (response.status == 200) {
            setStatus(response.status);
            setMessage(response.message);
            setOtpForm(!false);
            setInputForm(false);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          } else {
            clearTimerAttemp(getDeadTimeAttemp());
            setOtpForm(!false);
            setInputForm(false);
            setStatus(response.status);
            setMessage(response.message);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    }
  };

  const validateOtp = () => {
    setStateLogin(true);
    setStateNotLogin(false);
    closeModal();
    setOtpForm(!false);
    setInputForm(false);

    if (Otp) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("otp", Otp);

      dispatch(validateOTP(form)).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
    }
  };

  const getHeaderKlinik = (params) => {
    const par = localStorage.headerData
      ? JSON.parse(localStorage.headerData)
      : null;
    if (par == null || par.slug != params) {
      const payload = {
        slug: params,
      };

      dispatch(getKlinikHeader(payload))
        .then(() => {})
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    } else {
      const par = JSON.parse(localStorage.headerData);
      setKlinikHeader(par);
    }
  };

  return (
    <header className="w-100-p max-w-1320">
      <ModalLogin
        InputForm={InputForm}
        TimerOnGoing={TimerOnGoing}
        closeModal={closeModal}
        isShow={isShow}
        phone={phone}
        requestOtp={requestOtp}
        rerequestOtp={rerequestOtp}
        setOtp={setOtp}
        validateOtp={validateOTP}
        OtpForm={OtpForm}
        SpanPhone={SpanPhone}
        setPhone={setPhone}
        timer={timer}
        message={message}
        remainingTime={remainingTime}
        setInputForm={setInputForm}
        setOtpForm={setOtpForm}
        status={status}
      />

      <Navbar className="h-80">
        <Navbar.Brand href="/home">
          <img
            data-cfsrc="/img/logo/logo-hospita.svg"
            src="/img/logo/logo-hospita.svg"
            alt="logo"
            height="50"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          {localStorage.accessToken ? (
            <div>
              {localStorage?.firstName == "null" &&
              location.pathname !== "/account-profile-v2" ? (
                <Button
                  label="Lengkapi Data Anda"
                  icon={IconArrowWhite}
                  type="primary"
                  iconPosition="right"
                  className="px-4"
                  onClick={() => history.push("/account-profile-v2")}
                />
              ) : (
                <div className="avatar">
                  <button
                    className="circle-image-avatar-v2"
                    onClick={() => {
                      window.location = "/account-profile-v2";
                    }}
                  >
                    <img
                      src={
                        localStorage.avatar != "null"
                          ? localStorage.avatar
                          : "https://aido-app.s3-ap-southeast-1.amazonaws.com/assets/users/icons/anonymous.png"
                      }
                      width="40"
                      height="40"
                      alt={
                        localStorage.firstName != "null"
                          ? localStorage.firstName
                          : "Halo"
                      }
                    />
                  </button>
                  &nbsp; &nbsp;
                  <span className="username-v2 font-s-18 font-w-700">
                    Halo,{" "}
                    {localStorage.firstName != "null"
                      ? localStorage.firstName
                      : " "}
                    !
                  </span>
                </div>
              )}
            </div>
          ) : (
          klinikServices && klinikServices.length > 0 && (
            <Navbar.Text>
              <ButtonReact
                className="button-green-52"
                onClick={() => invokeModal(true)}
              >
                Masuk
              </ButtonReact>
            </Navbar.Text>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
