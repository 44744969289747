import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button as ButtonReact } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getKlinikHeader,
  otpRequest,
  validateOTP,
  logout,
} from "../../../../../actions";
import ModalLogin from "../../../../../components/Modal/LoginV2";
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
  storeLocalStorageItem,
} from "../../../../../helpers/localStorage";
import { isNumber } from "../../../../../helpers/utils";

const HeaderV2 = ({ isMenuShow, setIsMenuShow }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const [isShow, invokeModal] = React.useState(false);
  const [OtpForm, setOtpForm] = useState(false);
  const [wrongCode, setWrongCode] = useState(true);
  const [InputForm, setInputForm] = useState(!false);
  const [TimerOnGoing, setTimerOnGoing] = useState(false);
  const [StateNotLogin, setStateNotLogin] = useState(true);
  const [StateLogin, setStateLogin] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const [SpanPhone, setSpanPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(62);
  const [Otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [remainingTime, setRemainingTime] = useState("00:00");
  const [subdomain, setSubDomain] = useState(null);
  const [kliniHeader, setKlinikHeader] = useState([]);
  const [klinikServices, setKlinikServices] = useState([]);
  //   const [isMenuShow, setIsMenuShow] = useState(false);
  const Ref = useRef(null);
  const [dropdownUser, setDropdownUser] = useState(true);

  useEffect(() => {
    var timestampToday = moment().format("X");
    var expiredLocalstorage = localStorage.getItem("expired");

    if (timestampToday > expiredLocalstorage) {
      localStorage.clear();
    }

    const host = window.location.host;
    const arr = host.split(".");

    if (arr.length > 0) {
      if (arr[0] == "core-klinik-fe" || arr[0] == "localhost:5002") {
        getHeaderKlinik("klinikaido");
      } else {
        setSubDomain(arr[0]);
        getHeaderKlinik(arr[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (auth.headerData) {
      if (auth.headerData.klinik_group_id === null) {
        history.push("/404");
      }
    }
  }, [auth.headerData]);

  const initModal = () => {
    return invokeModal(!false);
  };

  const closeModal = () => {
    setPhone("");
    setCountryCode(62);
    setTimeout(() => {
      setOtpForm(false);
      setInputForm(true);
    }, 500);
    return invokeModal(false);
  };

  const handleSetPhone = (num) => {
    if (num) {
      if (isNumber(num)) {
        setPhone(num);
      }
    } else {
      setPhone("");
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const getTimeRemainingAttemp = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const startTimerAttemp = (e) => {
    let { total, minutes, seconds } = getTimeRemainingAttemp(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setRemainingTime(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:60");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const clearTimerAttemp = (e) => {
    setRemainingTime("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimerAttemp(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const getDeadTimeAttemp = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
    clearTimerAttemp(getDeadTimeAttemp());
  }, []);

  useEffect(() => {
    if (Otp) {
      if (Otp.length == 6) {
        validateOtp();
      }
    }
  }, [Otp]);

  useEffect(() => {
    if (auth.loginData) {
      if (auth.loginData.length != 0) {
        const lastPath = retrieveLocalStorageItem("lastPath");
        localStorage.setItem("userId", auth.loginData[0].userId);
        localStorage.setItem("accessToken", auth.loginData[0].accessToken);
        localStorage.setItem("avatar", auth.loginData[0].avatar);
        localStorage.setItem("fullName", auth.loginData[0].fullName);
        localStorage.setItem("firstName", auth.loginData[0].firstName);
        localStorage.setItem("lastName", auth.loginData[0].lastName);
        localStorage.setItem("phone", auth.loginData[0].phone);
        localStorage.setItem("expired", moment().add(23, "hour").format("X"));

        window.location = lastPath;
        removeLocalStorageItem("lastPath");
      }
    }
  }, [auth.loginData]);

  useEffect(() => {
    if (auth.headerData) {
      if (auth.headerData.length != 0) {
        localStorage.setItem("headerData", JSON.stringify(auth.headerData));
        const par = JSON.parse(localStorage.headerData);
        localStorage.setItem("hospitalGrupId", par.klinik_group_id);
        localStorage.setItem("klinikGrupId", par.klinik_group_id);
        localStorage.setItem("klinikLogo", par.klinik_logo);
        localStorage.setItem("klinikTerms", par.klinik_terms);
        localStorage.setItem("klinikSlogan", par.klinik_slogan);
        localStorage.setItem(
          "klinikServices",
          JSON.stringify(par.klinik_services)
        );
        setKlinikServices(par.klinik_services);
        localStorage.setItem("klinikId", par.klinik_id);
        setKlinikHeader(par);
      }
    }
  }, [auth.headerData]);

  const rerequestOtp = () => {
    setTimerOnGoing(false);
    clearTimer(getDeadTime());
  };

  const requestOtp = (e) => {
    setWrongCode(true);
    if (phone) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("country_code", `${countryCode}`);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("urlWeb", window.location.href);

      setSpanPhone(`+${countryCode}${phone}`);
      clearTimer(getDeadTime());

      dispatch(otpRequest(form))
        .then((response) => {
          if (response.status == 200) {
            setStatus(response.status);
            setMessage(response.message);
            setOtpForm(!false);
            setInputForm(false);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          } else {
            clearTimerAttemp(getDeadTimeAttemp());
            setOtpForm(!false);
            setInputForm(false);
            setStatus(response.status);
            setMessage(response.message);
            storeLocalStorageItem({
              storageKey: "lastPath",
              storageValue: window.location.pathname,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    }
  };

  const validateOtp = () => {
    if (Otp) {
      const form = new FormData();
      form.append("phone", phone);
      form.append("hospital_id", localStorage.getItem("klinikGrupId"));
      form.append("otp", Otp);

      dispatch(validateOTP(form))
        .then((res) => {
          if (res.status == 200) {
            setStateLogin(true);
            setStateNotLogin(false);
            setOtpForm(!false);
            setInputForm(false);
            closeModal();
            setWrongCode(true);
          } else {
            setWrongCode(false);
          }
        })
        .catch((error) => {
          setWrongCode(false);
        });
    }
  };

  const logOut = () => {
    dispatch(logout());
    window.location = "/home";
  };

  const getHeaderKlinik = (params) => {
    const par = localStorage.headerData
      ? JSON.parse(localStorage.headerData)
      : null;
    if (par == null || par.slug != params) {
      const payload = {
        slug: params,
      };

      dispatch(getKlinikHeader(payload))
        .then(() => {})
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
        });
    } else {
      const par = JSON.parse(localStorage.headerData);
      setKlinikHeader(par);
    }
  };

  const handleShowMenu = () => {
    if (isMenuShow) {
      setIsMenuShow(false);
    } else {
      setIsMenuShow(true);
    }
  };

  return (
    <header className="w-100-p max-w-1320">
      <ModalLogin
        InputForm={InputForm}
        TimerOnGoing={TimerOnGoing}
        closeModal={closeModal}
        isShow={isShow}
        phone={phone}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        requestOtp={requestOtp}
        rerequestOtp={rerequestOtp}
        Otp={Otp}
        setOtp={setOtp}
        validateOtp={validateOtp}
        OtpForm={OtpForm}
        SpanPhone={SpanPhone}
        setPhone={handleSetPhone}
        timer={timer}
        message={message}
        remainingTime={remainingTime}
        setInputForm={setInputForm}
        setOtpForm={setOtpForm}
        status={status}
        wrongCode={wrongCode}
      />

      <Navbar className="h-80">
        <Navbar.Brand href="/home">
          {kliniHeader.klinik_logo ? (
            <img
              data-cfsrc={kliniHeader.klinik_logo}
              src={kliniHeader.klinik_logo}
              alt="hospital-logo"
              height="50"
              className="d-inline-block align-top"
            />
          ) : null}
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          {localStorage.accessToken ? (
            <div>
              {localStorage?.firstName == "null" &&
              location.pathname !== "/account-profile-v2" ? (
                <>
                  <div className="d-lg-none">
                    <Navbar.Text>
                      <img
                        src="/img/icon/icon-profile.svg"
                        alt="login"
                        className="margin-r-8 cursor-point"
                        onClick={() => history.push("/account-profile-v2")}
                      />

                      <img
                        src={
                          isMenuShow
                            ? "/img/icon/icon-close.svg"
                            : "/img/icon/icon-menu.svg"
                        }
                        alt="menu"
                        className="cursor-point"
                        onClick={() => handleShowMenu()}
                      />
                    </Navbar.Text>
                  </div>

                  <div className="d-lg-inline d-none">
                    <Navbar.Text>
                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/about-us")}
                      >
                        Tentang Kami
                      </span>

                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/news")}
                      >
                        Berita & Informasi
                      </span>

                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/article")}
                      >
                        Artikel Kesehatan
                      </span>

                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/contact-us")}
                      >
                        Hubungi Kami
                      </span>

                      <div className="d-inline-block line-green-40 margin-l-34"></div>

                      <div
                        className="d-inline-block"
                        onClick={() => {
                          dropdownUser
                            ? setDropdownUser(false)
                            : setDropdownUser(true);
                        }}
                      >
                        <img
                          src="/img/icon/icon-profile.svg"
                          alt="login"
                          className="margin-l-12 cursor-point"
                        />
                        <span className="font-s-14 font-w-700 cl-black hover-cl-green cursor-point">
                          {localStorage.firstName != "null"
                            ? localStorage.firstName
                            : " "}
                          <span
                            className="d-inline-block margin-l-8"
                            style={{ transform: "rotate(180deg)" }}
                          >
                            &#8963;
                          </span>
                        </span>
                      </div>
                      <div
                        hidden={dropdownUser}
                        style={{
                          position: "fixed",
                          zIndex: "999",
                          textAlign: "right",
                          marginTop: "80px",
                          top: "2px",
                          right: "4%",
                          backgroundColor: "#ffffff",
                          borderRadius: "0px 0px 8px 8px",
                        }}
                      >
                        <div
                          style={{ padding: "16px", minWidth: "175px" }}
                          className="font-s-14 font-w-700 cl-black hover-cl-green cursor-point"
                          onClick={() => history.push("/account-profile-v2")}
                        >
                          Lihat Profil
                        </div>
                        <div style={{ padding: "0px 16px", minWidth: "175px" }}>
                          <hr style={{ margin: "0px" }} />
                        </div>
                        <div
                          style={{ padding: "16px", minWidth: "175px" }}
                          className="font-s-14 font-w-700 cl-black hover-cl-green cursor-point"
                          onClick={() => logOut()}
                        >
                          Keluar
                        </div>
                      </div>
                    </Navbar.Text>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-lg-none">
                    <Navbar.Text>
                      <img
                        src="/img/icon/icon-profile.svg"
                        alt="login"
                        className="margin-r-8 cursor-point"
                        onClick={() => history.push("/account-profile-v2")}
                      />

                      <img
                        src={
                          isMenuShow
                            ? "/img/icon/icon-close.svg"
                            : "/img/icon/icon-menu.svg"
                        }
                        alt="menu"
                        className="cursor-point"
                        onClick={() => handleShowMenu()}
                      />
                    </Navbar.Text>
                  </div>

                  <div className="d-lg-inline d-none">
                    <Navbar.Text>
                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/about-us")}
                      >
                        Tentang Kami
                      </span>

                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/news")}
                      >
                        Berita & Informasi
                      </span>

                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/article")}
                      >
                        Artikel Kesehatan
                      </span>

                      <span
                        className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                        onClick={() => history.push("/contact-us")}
                      >
                        Hubungi Kami
                      </span>

                      <div className="d-inline-block line-green-40 margin-l-34"></div>

                      <div
                        className="d-inline-block"
                        onClick={() => {
                          dropdownUser
                            ? setDropdownUser(false)
                            : setDropdownUser(true);
                        }}
                      >
                        <img
                          src="/img/icon/icon-profile.svg"
                          alt="login"
                          className="margin-l-12 cursor-point"
                        />
                        <span className="font-s-14 font-w-700 cl-black hover-cl-green cursor-point">
                          {localStorage.firstName != "null"
                            ? localStorage.firstName
                            : " "}
                          <span
                            className="d-inline-block margin-l-8"
                            style={{ transform: "rotate(180deg)" }}
                          >
                            &#8963;
                          </span>
                        </span>
                      </div>
                      <div
                        hidden={dropdownUser}
                        style={{
                          position: "fixed",
                          zIndex: "999",
                          textAlign: "right",
                          marginTop: "80px",
                          top: "2px",
                          right: "4%",
                          backgroundColor: "#ffffff",
                          borderRadius: "0px 0px 8px 8px",
                        }}
                      >
                        <div
                          style={{ padding: "16px", minWidth: "175px" }}
                          className="font-s-14 font-w-700 cl-black hover-cl-green cursor-point"
                          onClick={() => history.push("/account-profile-v2")}
                        >
                          Lihat Profil
                        </div>
                        <div style={{ padding: "0px 16px", minWidth: "175px" }}>
                          <hr style={{ margin: "0px" }} />
                        </div>
                        <div
                          style={{ padding: "16px", minWidth: "175px" }}
                          className="font-s-14 font-w-700 cl-black hover-cl-green cursor-point"
                          onClick={() => logOut()}
                        >
                          Keluar
                        </div>
                      </div>
                    </Navbar.Text>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="d-lg-none">
                <Navbar.Text>
                  <img
                    src="/img/icon/icon-login.svg"
                    alt="login"
                    className="margin-r-8 cursor-point"
                    onClick={() => invokeModal(true)}
                  />

                  <img
                    src={
                      isMenuShow
                        ? "/img/icon/icon-close.svg"
                        : "/img/icon/icon-menu.svg"
                    }
                    alt="menu"
                    className="cursor-point"
                    onClick={() => handleShowMenu()}
                  />
                </Navbar.Text>
              </div>

              <div className="d-lg-inline d-none">
                <Navbar.Text>
                  <span
                    className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                    onClick={() => history.push("/about-us")}
                  >
                    Tentang Kami
                  </span>

                  <span
                    className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                    onClick={() => history.push("/news")}
                  >
                    Berita & Informasi
                  </span>

                  <span
                    className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                    onClick={() => history.push("/article")}
                  >
                    Artikel Kesehatan
                  </span>

                  <span
                    className="font-s-14 font-w-700 cl-black hover-cl-green margin-l-34 cursor-point"
                    onClick={() => history.push("/contact-us")}
                  >
                    Hubungi Kami
                  </span>

                  {
                    klinikServices && klinikServices.length > 0 && (
                      <div className="d-inline-block line-green-40 margin-l-34"></div>
                    )
                  }
                  {
                    klinikServices && klinikServices.length > 0 && (
                      <ButtonReact
                        className="button-green-40 margin-l-12"
                        onClick={() => invokeModal(true)}
                      >
                        Masuk
                      </ButtonReact>
                    )
                  }

                </Navbar.Text>
              </div>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default HeaderV2;
