import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { IconArrowGrey, IconIndoFlag } from '../../../assets/img/icon';
import './index.scss';
import { SelectOptions } from '../../Input';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryCode } from '../../../actions';

const ModalLogin = ({
  isShow,
  closeModal,
  InputForm,
  setInputForm,
  requestOtp,
  Otp,
  setOtp,
  TimerOnGoing,
  rerequestOtp,
  validateOtp,
  phone,
  setPhone,
  countryCode,
  setCountryCode,
  OtpForm,
  setOtpForm,
  SpanPhone,
  status,
  message,
  timer,
  remainingTime,
  wrongCode
}) => {
  const dispatch = useDispatch();

  const patient = useSelector((state) => state.patient);

  const [optionsCountryCode, setOptionsCountryCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    label: "Indonesia",
    value: 101
  });

  useEffect(() => {
    dispatch(getCountryCode());
  }, []);

  useEffect(() => {
    let countryCode = [];

    if (patient?.countryCode?.length > 0) {
      patient.countryCode.map((data) => {
        countryCode.push({
          value: data.id,
          label: data.countryName,
          internationalDialing: data.internationalDialing,
        });
      });
    }

    setOptionsCountryCode(countryCode);
  }, [patient]);

  const handleCLoseModal = () => {
    setSelectedCountryCode({
      label: "Indonesia",
      value: 101
    });
    closeModal();
  }

  return (
    <React.Fragment>
      <Modal
        dialogClassName="modal-dialogs-margin-0 modal-dialogs modal-mobile-v2"
        show={isShow}
        centered
        onHide={handleCLoseModal}
        size="sm"
      >
        <Modal.Body className="modal-container" centered>
          {InputForm && (
            <div>
              <h1 className="mb-3">Selamat Datang</h1>
              <p className="mb-3">
                Silahkan masukkan nomor handphone Anda
              </p>
              {status == 400 && remainingTime !== '00:00' && (
                <>
                  <p className="mb-3" style={{ color: 'red' }}>
                    {message} {remainingTime}
                  </p>
                </>
              )}
              <Row>
                <Col md={4}>
                  <SelectOptions
                    placeholder="Pilih"
                    background="#ffff"
                    value={selectedCountryCode}
                    fontSize="lg"
                    setValue={(e) => {
                      setSelectedCountryCode(e);
                      setCountryCode(
                        optionsCountryCode[e.value - 1]?.internationalDialing,
                      );
                    }}
                    options={optionsCountryCode}
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                <Col md={8}>
                  <div className="input-icons d-flex my-auto">
                    <div className="prefix-country-code">
                      <span className="font-s-16 font-w-500">{countryCode}</span>
                    </div>
                    
                    <input
                      type="text"
                      value={phone}
                      className="input-phoneNumber padding-l-60"
                      placeholder="8123456789"
                      onChange={(e) => setPhone(e.target.value)}
                      pattern="[0-9]*"
                      maxlength="14"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          requestOtp();
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <p style={{marginBottom: "8px"}}>
                Kami akan mengirimkan kode verifikasi ke nomor ini. Pastikan nomor Anda aktif dan dapat dihubungi.
              </p>
              <label className="information-tnc" style={{marginBottom: "8px"}}>
                Saya menyetujui
                <a href="/terms-and-conditions" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Ketentuan Pengguna Janji Temu{' '}
                </a>
                dan
                <a href="/privacy-policy" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Kebijakan Privasi.
                </a>
              </label>
              <div className="mt-4">
                <Button
                    onClick={() => requestOtp()}
                    style={{width: "100%", paddingTop: "8px", paddingBottom: "8px"}}
                >
                  Verifikasi
                </Button>
              </div>
            </div>
          )}
          {OtpForm && status == 400 && (
            <div>
              <h1 className="mb-3">Selamat Datang</h1>
              <p className="mb-3">
                Silahkan masukkan nomor handphone Anda
              </p>
              {status == 400 && remainingTime !== '00:00' && (
                <>
                  <p className="mb-3" style={{ color: 'red' }}>
                    {message} {remainingTime}
                  </p>
                </>
              )}
              <Row>
                <Col md={4}>
                  <SelectOptions
                    placeholder="Pilih"
                    background="#ffff"
                    value={selectedCountryCode}
                    fontSize="lg"
                    setValue={(e) => {
                      setSelectedCountryCode(e);
                      setCountryCode(
                        optionsCountryCode[e.value - 1]?.internationalDialing,
                      );
                    }}
                    options={optionsCountryCode}
                    customIcon={IconArrowGrey}
                    iconClassName="mr-2"
                  />
                </Col>
                <Col md={8}>
                  <div className="input-icons d-flex my-auto">
                    <div className="prefix-country-code">
                      <span className="font-s-16 font-w-500">{countryCode}</span>
                    </div>
                    
                    <input
                      type="text"
                      value={phone}
                      className="input-phoneNumber padding-l-60"
                      placeholder="8123456789"
                      onChange={(e) => setPhone(e.target.value)}
                      pattern="[0-9]*"
                      maxlength="14"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          requestOtp();
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <p className="mb-3 mt-4 mt-lg-3">
                Kami akan mengirimkan kode verifikasi ke nomor ini. Pastikan nomor Anda aktif dan dapat dihubungi.
              </p>
              <label className="information-tnc">
                Saya menyetujui
                <a href="/terms-and-conditions" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Ketentuan Pengguna Janji Temu{' '}
                </a>
                dan
                <a href="/privacy-policy" target="_blank" style={{ color: '#00BD84' }}>
                  {' '}
                  Kebijakan Privasi.
                </a>
              </label>
              <div className="mt-4">
                <Button
                    onClick={() => requestOtp()}
                    style={{width: "100%", paddingTop: "8px", paddingBottom: "8px"}}
                >
                  Verifikasi
                </Button>
              </div>
            </div>
          )}
          <div>
            {OtpForm && status == 200 && (
              <div>
                <h1 className="mb-3 d-none d-md-block">Masukkan Kode OTP</h1>
                <h4 className="mb-3 d-md-none d-block">Masukkan Kode OTP</h4>
                <p className="mb-3 mt-4" hidden={!wrongCode}>
                  Masukkan 6-digit kode yang dikirim melalui WA ke {SpanPhone}
                </p>
                <p className="mb-3 mt-4" hidden={wrongCode}>
                  <span style={{color: "#E02B1D"}}>Kode yang Anda masukkan salah</span>
                </p>
                <div className={`d-flex align-items-center justify-content-center mb-3 ${!wrongCode ? "pincode-input-text-red" : ""}`}>
                  <PinInput
                    length={6}
                    initialValue=""
                    secret
                    onChange={(value) => {
                      setOtp(value);
                    }}
                    type="numeric"
                    inputMode="number"
                    onComplete={(value, index) => {}}
                    autoSelect
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </div>
                <p className="mb-3">{timer}</p>
                {timer == "00:00" ? (
                  <>
                    <p className="mb-3">
                      <a
                        href="#"
                        style={{ fontWeight: 'bold' }}
                        className="green"
                        onClick={(e) => {
                          requestOtp()
                          rerequestOtp()
                        }}
                      >
                        Kirim ulang kode OTP
                      </a>
                    </p>
                  </>
                ) : null}
                <Button
                    onClick={() => validateOtp()}
                    className="link-login"
                    style={{width: "100%", paddingTop: "8px", paddingBottom: "8px"}}
                    disabled={Otp.length == 6 ? false : true}
                >
                  Verifikasi
                </Button>
                <p className="mb-3">
                  <a
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    className="green"
                    onClick={(e) => {
                      setOtpForm(false);
                      setInputForm(true);
                    }}
                  >
                    Gunakan nomor lain?
                  </a>
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalLogin;
