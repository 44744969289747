import React, { useEffect, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutV2 from "../Components/LayoutV2";
import {
  getKlinikHeader,
  getAllArticles,
  getAllBanner,
} from "../../../actions";
import queryString from "query-string";
import "./index.scss";
import Swal from "sweetalert2";
import { getHospitalInfo } from "../../../actions/appointmentWeb/appointmentWeb.actions";
import ModalComp from "../../../components/Modal";
import Button from "../../../components/Button";

const HomeV2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const articles = useSelector((state) => state.articles);
  const appointmentWeb = useSelector((state) => state.appointmentWeb);

  const [klinikServices, setKlinikServices] = useState([]);
  const [klinikSlogan, setKlinikSlogan] = useState("");

  const hospitalGroupId = localStorage.getItem("hospitalGrupId");

  const [articleData, setArticleData] = useState([]);

  const [bannerData, setBannerData] = useState([]);

  const [partnerDataDesktop, setPartnerDataDesktop] = useState([]);
  const [partnerDataMobile, setPartnerDataMobile] = useState([]);

  const [selectedPartnerData, setSelectedPartnerData] = useState(null);
  const [showPartnerModal, setShowPartnerModal] = useState(false);

  const [activeSlideDesk, setActiveSlideDesk] = useState(0);
  const [activeSlideMobile, setActiveSlideMobile] = useState(0);

  useEffect(() => {
    const host = window.location.host;
    const arr = host.split(".");

    if (arr.length > 0) {
      if (arr[0] == "core-klinik-fe" || arr[0] == "localhost:5002") {
        getHeaderKlinik("klinikaido");
      } else {
        getHeaderKlinik(arr[0]);
      }
    }

    let values = {
      klinikGroupId: hospitalGroupId,
      isParent: false,
      active: "1",
      sort: `[{"created_date": "ASC"}]`,
    };

    let query = queryString.stringify(values);

    dispatch(getHospitalInfo(query));
  }, []);

  useEffect(() => {
    const modifyArrayIntoNested = (inputArray, groupSize) => {
      let nestedArray = [];
      if (inputArray) {
        for (let i = 0; i < inputArray.length; i += groupSize) {
          nestedArray.push(inputArray.slice(i, i + groupSize));
        }  
      }
      
      return nestedArray;
    };

    setPartnerDataDesktop(
      modifyArrayIntoNested(appointmentWeb.hospitalInfoData, 6)
    );
    setPartnerDataMobile(
      modifyArrayIntoNested(appointmentWeb.hospitalInfoData, 3)
    );
  }, [appointmentWeb.hospitalInfoData]);

  useEffect(() => {
    if (articles?.articlesData?.list?.length > 0) {
      let data = [];
      articles.articlesData.list.map((dataItem) => {
        var item = {
          id: dataItem.id,
          date: dataItem.createdDate,
          title: dataItem.title,
          imageUrl: dataItem.thumbnail,
          description: dataItem?.content
            ? dataItem?.content
                .replace(/<img[^>]*>/g, "")
                .replace(/(<([^>]+)>)/gi, "")
            : "",
          slug: dataItem.slug,
        };

        data.push(item);
      });
      setArticleData(data);
    }

    if (articles?.bannerData?.list?.length > 0) {
      let dataBanner = [];
      articles.bannerData.list.map((dataItem) => {
        var item = {
          id: dataItem.id,
          date: dataItem.createdDate,
          title: dataItem.title,
          imageUrl: dataItem.thumbnail,
          slug: dataItem.slug,
        };

        dataBanner.push(item);
      });
      setBannerData(dataBanner);
    }
  }, [articles]);

  useEffect(() => {
    if (hospitalGroupId) {
      let params = {
        klinik_group_id: hospitalGroupId,
        is_published: true,
        active: "1",
        type: "articles",
        pageSize: 6,
        sort: JSON.stringify([{ created_date: "DESC" }]),
      };
      dispatch(getAllArticles(queryString.stringify(params)));

      let paramsBanner = {
        klinik_group_id: hospitalGroupId,
        is_published: true,
        active: "1",
        type: "banner",
        pageSize: 6,
        sort: JSON.stringify([{ created_date: "DESC" }]),
      };
      dispatch(getAllBanner(queryString.stringify(paramsBanner)));
    }
  }, [hospitalGroupId]);

  useEffect(() => {
    if (auth.headerData) {
      if (auth.headerData.klinik_group_id === null) {
        history.push("/404");
      }

      if (auth.headerData.length != 0) {
        localStorage.setItem("headerData", JSON.stringify(auth.headerData));

        const par = JSON.parse(localStorage.headerData);

        setKlinikSlogan(par.klinik_slogan);
        setKlinikServices(par.klinik_services);
      }
    }
  }, [auth.headerData]);

  const getHeaderKlinik = (params) => {
    if (params) {
      const payload = {
        slug: params,
      };

      dispatch(getKlinikHeader(payload)).catch((error) => {
        console.log("error", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
    }
  };

  const handleProductList = (serviceType, serviceName, listName, url) => {
    if (serviceType === "PK") {
      return history.push(`/${url}`);
    }

    if (
      (localStorage.getItem("klinikId") &&
        localStorage.getItem("klinikId") !== "null") ||
      serviceType === "PM" ||
      serviceType === "JT"
    ) {
      const search = {
        hospital_group_id: localStorage.getItem("klinikGrupId"),
        hospital_id: [localStorage.getItem("klinikId")],
        name_doctor: undefined,
        serviceType: serviceType,
        titleList: listName,
      };

      history.push(`list-v2/${url}`, [search]);
    } else {
      const index = klinikServices.findIndex((x) => x.code === serviceType);

      history.push(`find/${klinikServices[index].url}`, [
        { serviceType, serviceName, listName, url },
      ]);
    }
  };

  const handlePartnerClick = (data) => {
    setSelectedPartnerData(data);
    setShowPartnerModal(true);
  };

  const renderPartnerModal = () => {
    return (
      <ModalComp
        title={selectedPartnerData?.name}
        show={showPartnerModal}
        setShow={() => setShowPartnerModal(false)}
      >
        <Row className="margin-b-12">
          <Col>
            <Button
              label="Info Selengkapnya"
              type="primary"
              className="font-s-16"
              onClick={() =>
                history.push(`/partner-us/${selectedPartnerData.id}`)
              }
            />
          </Col>
        </Row>

        {selectedPartnerData?.phone_1 && (
          <Row className="margin-b-12">
            <Col>
              <Button
                label="Hubungi PIC"
                type="primary"
                className="font-s-16"
                onClick={() =>
                  window.open(
                    `https://wa.me/${selectedPartnerData.phone_1}`,
                    "_blank"
                  )
                }
              />
            </Col>
          </Row>
        )}

        {selectedPartnerData?.website_url && (
          <Row className="margin-b-12">
            <Col>
              <Button
                label="Kunjungi Website"
                type="primary"
                className="font-s-16"
                onClick={() =>
                  window.open(selectedPartnerData.website_url, "_blank")
                }
              />
            </Col>
          </Row>
        )}
      </ModalComp>
    );
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-24">
        <Col xs={12}>
          <Carousel
            autoPlay={true}
            interval={3000}
            indicators={false}
            fade={true}
          >
            {bannerData.length > 0
              ? bannerData.map((data, index) => (
                  <Carousel.Item
                    key={index}
                    onClick={() => history.push(`${data.slug}`)}
                  >
                    <img src={data.imageUrl} className="carousel-image" />
                  </Carousel.Item>
                ))
              : null}
          </Carousel>
        </Col>
      </Row>

      {
        klinikServices && klinikServices.length > 0 && (
          <Row className="margin-b-24">
            <Col className="margin-b-16 d-flex align-items-center" xs={12} lg={2}>
              <span className="font-title-24-16 font-w-700">Layanan Kami</span>
            </Col>

            <Col xs={12} lg={10}>
              <div className="home-product-card">
                <Row>
                  {klinikServices.map((data, index) => (
                    <div key={index} className="col-lg-4 col-xs-12">
                      <div key={"col" + data.id} className="d-lg-none">
                        <Row
                          className="cursor-point"
                          onClick={() =>
                            handleProductList(
                              data.code,
                              data.titleFind,
                              data.titleList,
                              data.url
                            )
                          }
                        >
                          <Col className="my-1" xs={12}>
                            <div
                              className="home-product-card-item"
                              style={data.style}
                            >
                              <img
                                src={data.image}
                                alt="service"
                                className="home-product-icon-desk"
                              />

                              <span className="font-title-24 font-w-700">
                                {data.name}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div
                        key={"colm" + data.id}
                        className="d-lg-inline d-none cursor-point"
                        onClick={() =>
                          handleProductList(
                            data.code,
                            data.titleFind,
                            data.titleList,
                            data.url
                          )
                        }
                      >
                        <div
                          className="d-flex justify-content-left home-product-card-item"
                          style={data.style}
                        >
                          <img
                            src={data.image}
                            alt="service"
                            className="home-product-icon-desk"
                          />

                          <span className="font-title-24-12 font-w-700">
                            {data.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        )
      }

      {
        partnerDataMobile && partnerDataMobile.length > 0 && (         
          <Row className="margin-b-16">
            <Col xs={12} className="d-lg-none">
              <span className="font-title-24-16 font-w-700">Mitra Kami</span>
            </Col>

            <Col xs={12} className="d-lg-inline d-none text-center">
              <span className="font-title-24-16 font-w-700">Mitra Kami</span>
            </Col>
          </Row>
        )
      }

      {
        partnerDataMobile && partnerDataMobile.length > 0 && (   
          <Row className="d-lg-none d-md-none d-sm-none">
            <Col className="margin-b-24">
              <Carousel
                className={`carousel-partner${
                  activeSlideMobile > 0 ? "" : " carousel-partner-prev-hide"
                }${
                  activeSlideMobile === partnerDataMobile.length - 1
                    ? " carousel-partner-next-hide"
                    : ""
                }`}
                autoPlay={false}
                interval={null}
                indicators={false}
                onSelect={(val) => setActiveSlideMobile(val)}
              >
                {partnerDataMobile.map((data, index) => (
                  <Carousel.Item key={index}>
                    <Row className="d-flex justify-content-center align-items-center min-h-100-px">
                      {data.map((childData, childIndex) => (
                        <Col className="text-center" xs={4} key={childIndex}>
                          <img
                            src={childData.logo_url}
                            className="carousel-partner-image"
                            onClick={() => handlePartnerClick(childData)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        )
      }

      {
        partnerDataDesktop && partnerDataDesktop.length > 0 && (   
          <Row className="d-lg-flex d-sm-flex d-none">
            <Col className="margin-b-24">
              <Carousel
                className={`carousel-partner${
                  activeSlideDesk > 0 ? "" : " carousel-partner-prev-hide"
                }${
                  activeSlideDesk === partnerDataDesktop.length - 1
                    ? " carousel-partner-next-hide"
                    : ""
                }`}
                autoPlay={false}
                interval={null}
                indicators={false}
                onSelect={(val) => setActiveSlideDesk(val)}
              >
                {partnerDataDesktop.map((data, index) => (
                  <Carousel.Item key={index}>
                    <Row className="d-flex justify-content-center align-items-center min-h-100-px">
                      {data.map((childData, childIndex) => (
                        <Col className="text-center" lg={2} key={childIndex}>
                          <img
                            src={childData.logo_url}
                            className="carousel-partner-image"
                            onClick={() => handlePartnerClick(childData)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        )
      }

      <Row className="margin-b-16">
        <Col xs={12}>
          <span className="font-title-24-16 font-w-700">Artikel Kesehatan</span>
        </Col>
      </Row>

      <Row className="margin-b-16">
        {articleData.map((data, index) => (
          <Col xs={12} lg={6} key={index}>
            <Row
              className="margin-b-16 cursor-point"
              onClick={() => history.push(`/article/${data.slug}`)}
            >
              <Col xs={4} lg={3}>
                <img src={data.imageUrl} className="home-article-image" />
              </Col>

              <Col xs={8} lg={9}>
                <div className="d-flex flex-column">
                  <span className="font-s-14 font-w-500 cl-green">
                    {data.date}
                  </span>

                  <span className="font-s-16 font-w-700 margin-b-8">
                    {data.title}
                  </span>

                  <span
                    className="home-article-desc font-s-14 font-w-500"
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  ></span>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>

      <Row className="margin-b-16">
        <Col className="text-right" xs={12}>
          <span
            className="font-s-16 font-w-700 cl-green cursor-point"
            onClick={() => history.push("/article")}
          >
            Lihat Semua
          </span>
        </Col>
      </Row>

      {renderPartnerModal()}
    </LayoutV2>
  );
};

export default HomeV2;
