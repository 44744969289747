import axios from "../helpers/axios";
import axiosHisApi from "../helpers/axiosHisApi";
import { patientConstants } from "./constants";

export const getAllPatients = (userId) => {
  return async (dispatch) => {
    dispatch({ type: patientConstants.GET_ALL_PATIENT_REQUEST });
    const res = await axios.get(`/profiles/list/${userId}`);
    if (res.status === 200) {
      dispatch({
        type: patientConstants.GET_ALL_PATIENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: patientConstants.GET_ALL_PATIENT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const uploadMedicalDocFile = (file, docName) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: patientConstants.UPLOAD_MEDICALDOC_REQUEST });
    const res = await axios.post(`/appointments/upload-doc?docType=medical&docName=${docName}`, formData);
    if (res.status === 200) {
      const { data, status } = res;
      console.log('uploadMedicalDocFile', data)
      dispatch({
        type: patientConstants.UPLOAD_MEDICALDOC_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: patientConstants.UPLOAD_MEDICALDOC_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getCountryCode = () => {
	return async (dispatch) => {
		dispatch({ type: patientConstants.GET_COUNTRY_CODE_REQUEST });

		const res = await axiosHisApi.get(`/v1/api/patients/country-code`);
		
		if (res.data.status === 200) {
			dispatch({
				type: patientConstants.GET_COUNTRY_CODE_SUCCESS,
				payload: res.data,
			});

			return res.data;
		} else {
			dispatch({
				type: patientConstants.GET_COUNTRY_CODE_FAILURE,
				payload: { error: res.data.error },
			});
		}
	};
};