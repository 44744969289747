import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Calendar, utils } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  getDisabledDate,
  getHospitalPayer,
  getSlotTime,
  otpRequest,
  validateOTP,
} from '../../../actions';
import {
  IconArrowGreen,
  IconDummyAvatar,
  IconDummyPlace,
} from '../../../assets/img/icon';
import Button from '../../../components/Button';
import ModalLogin from '../../../components/Modal/Login';
import {
  wordingDoctorSchedule,
  wordingHopitalType,
} from '../../../constants/wording';
import { formatRupiah } from '../../../helpers/formatDigit';
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
  storeLocalStorageItem,
} from '../../../helpers/localStorage';
import { formatDateAsData } from '../../../helpers/utils';
import Layout from '../Components/Layout';
import './doctorSchedule.scss';
import MobileDoctorSchedule from './MobileDoctorSchedule';

moment.locale('id');

const DoctorSchedule = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [SpanPhone, setSpanPhone] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState(62);
  const [TimerOnGoing, setTimerOnGoing] = useState(false);
  const [InputForm, setInputForm] = useState(!false);
  const [OtpForm, setOtpForm] = useState(false);
  const [timer, setTimer] = useState('00:00');
  const Ref = useRef(null);
  const [StateNotLogin, setStateNotLogin] = useState(true);
  const [StateLogin, setStateLogin] = useState(false);
  const [Otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(0);
  const [remainingTime, setRemainingTime] = useState('00:00');

  const [selectedDay, setSelectedDay] = useState(null);
  const [disableDate, setDisabledDate] = useState();
  const [enabledDate, setEnabledDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [scheduleSlotIndex, setScheduleSlotIndex] = useState(-1);
  const [timeSlotContainer, settimeSlotContainer] = useState(null);
  const [scheduleSlotContainer, setScheduleSlotContainer] = useState(null);
  const [initScheduleSlotContainer, setInitScheduleSlotContainer] =
    useState(true);
  const [dateScheduleData, setdateScheduleData] = useState(null);
  const [consultationTypeData, setconsultationTypeData] = useState(null);
  const [timeSlotData, settimeSlotData] = useState(null);
  const [checkedRadioButton, setCheckedRdioButton] = useState('');
  const [payerBPJS, setPayerBPJS] = useState([]);
  const [payerNonBPJS, setPayerNonBPJS] = useState([]);
  const [mappingConsultationType, setMappingConsultationType] = useState([]);

  const { payerData } = useSelector((state) => state.paymentMethod);
  const insurance = useSelector((state) => state.paymentMethod);
  const doctorSchedule = useSelector((state) => state.doctorSchedule);
  const curYear = moment().year();
  const navigate = useHistory();

  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    const lastPath = retrieveLocalStorageItem('lastPath');
    const selectedDate = retrieveLocalStorageItem('selectedDate');
    const selectedConsultationType = retrieveLocalStorageItem(
      'selectedConsultationType'
    );
    const selectedTime = retrieveLocalStorageItem('selectedTime');

    setCheckedRdioButton(selectedConsultationType || null);
    setSelectedDay(selectedDate || null);
    settimeSlotData(selectedTime || null);
    settimeSlotContainer(true);

    if (lastPath) {
      window.location = lastPath;
      removeLocalStorageItem('lastPath');
    }
  }, []);

  useEffect(() => {
    if (Otp) {
      if (Otp.length == 6) {
        validateOtp();
      }
    }
  }, [Otp]);

  const closeModal = () => {
    setPhone('');
    setCountryCode(62);
    return setShowModal(false);
  };

  const rerequestOtp = () => {
    setTimerOnGoing(false);
    clearTimer(getDeadTime());
  };

  const getTimeRemainingAttemp = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimerAttemp = (e) => {
    let { total, minutes, seconds } = getTimeRemainingAttemp(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setRemainingTime(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimerAttemp = (e) => {
    setRemainingTime('00:30');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimerAttemp(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTimeAttemp = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const requestOtp = () => {
    if (phone) {
      const form = new FormData();
      form.append('phone', phone);
      form.append("country_code", `${countryCode}`);
      form.append('hospital_id', localStorage.getItem('klinikGrupId'));
      form.append('urlWeb', window.location.href);

      setSpanPhone(`+${countryCode}${phone}`);
      clearTimer(getDeadTime());

      dispatch(otpRequest(form))
        .then((response) => {
          if (response.status == 200) {
            setStatus(response.status);
            setMessage(response.message);
            setOtpForm(!false);
            setInputForm(false);
            storeLocalStorageItem({
              storageKey: 'lastPath',
              storageValue: window.location.pathname,
            });
          } else {
            clearTimerAttemp(getDeadTimeAttemp());
            setOtpForm(!false);
            setInputForm(false);
            setStatus(response.status);
            setMessage(response.message);
            storeLocalStorageItem({
              storageKey: 'lastPath',
              storageValue: window.location.pathname,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          });
        });
    }
  };

  const clearTimer = (e) => {
    setTimer('00:60');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (minutes == 0 && seconds == 0) setTimerOnGoing(true);
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const validateOtp = (e) => {
    setStateLogin(true);
    setStateNotLogin(false);
    closeModal();
    setOtpForm(!false);
    setInputForm(false);

    if (Otp) {
      const form = new FormData();
      form.append('phone', phone);
      form.append('hospital_id', localStorage.getItem('klinikGrupId'));
      form.append('otp', Otp);

      dispatch(validateOTP(form)).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
        });
      });
    }
  };

  const handleDoctorSearch = () => {
    const params = {
      ...state[0],
      hospital_id: state[0].search_id_hospital?.length
        ? [state[0].search_id_hospital[0]]
        : [],
      specialization_id: state[0]?.search_specialization_id || null,
      hospital_group_id: state[0]?.hospital_group_id || null,
      schedule_date: state[0]?.schedule_date || null,
      gender: state[0]?.search_gender || null,
      consultation: state[0]?.search_consultation_type || null,
    };
    navigate.push(`/result-find-doctor`, [params]);
  };

  const handleRadioButton = (e) => {
    storeLocalStorageItem({
      storageKey: 'selectedConsultationType',
      storageValue: e.target.value,
    });
    setCheckedRdioButton(e.target.value);
  };

  const resetRadio = () => {
    if (selectedDay == null) {
      setScheduleSlotContainer(null);
      setInitScheduleSlotContainer(true);
    } else {
      setScheduleSlotContainer(true);
      setInitScheduleSlotContainer(null);
    }
  };

  const handleSubmit = () => {
    switch (state[0].serviceType) {
      case 'TL':
        fillScheduleData();
        break;
      case 'JT':
        fillScheduleData();
        break;
      case 'PM':
        fillProcedureData();
      default:
        break;
    }
  };

  const fillScheduleData = () => {
    if (dateScheduleData == null) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tanggal Konsultasi',
      });
    } else if (consultationTypeData == null) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tipe Pembayaran Konsultasi',
      });
    } else if (timeSlotData == null) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Jam Konsultasi',
      });
    } else if (
      dateScheduleData != null &&
      consultationTypeData != null &&
      timeSlotData != null
    ) {
      var timeSlotSplit = timeSlotData.toString().split('-');
      const payloadScheduleData = {
        schedule_id:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex]['schedule_id'],
        doctor_schedule_id:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex]
            ?.doctor_schedule_id,
        consultation_type:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex][
            'consultation_type'
          ],
        consultation_name:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex][
            'consultation_name'
          ],
        scheduleType:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex]['schedule_type'],
        date: dateScheduleData,
        from_time: timeSlotSplit[0],
        to_time: timeSlotSplit[1],
        // no: "1",
        note: '',
        doctor_id: state[0].doctor_id,
        doctor_hospital_id: state[0].doctor_hospital_id,
        specialization_id: state[0].specialization_id,
        poli_id: state[0].poli_id,
        doctorName: state[0].doctor_name,
        hospital_id: state[0].hospital_id,
        hospitalName: state[0].hospital_name,
        paymentMethodtype: consultationTypeData,
        hospitalType: state[0].hospital_type,
        complaints: '',
        timezone: state[0].timezone,
        serviceType: state[0].serviceType,
        hospitalPayer:
          doctorSchedule.doctorScheduleData[scheduleSlotIndex][
            'consultation_type'
          ] === 2
            ? payerBPJS
            : payerNonBPJS,
      };

      if (localStorage.accessToken) {
        let params = {};
        params['name_doctor'] = state[0].name_doctor;
        params['search_id_hospital'] = state[0].search_id_hospital?.length
          ? [state[0].search_id_hospital[0]]
          : [];
        params['search_specialization_id'] =
          state[0].search_specialization_id || null;
        params['search_gender'] = state[0].search_gender || null;
        params['search_consultation_type'] =
          state[0].search_consultation_type || null;
        params['hospital_group_id'] = state[0].hospital_group_id || null;
        params['doctor_imageurl'] = state[0].doctor_imageurl || null;
        params['doctor_name'] = state[0].doctor_name || null;
        params['specialization_name'] = state[0].specialization_name || null;
        params['hospital_image_url'] = state[0].hospital_image_url || null;
        params['hospital_name'] = state[0].hospital_name || null;
        params['hospital_address'] = state[0].hospital_address || null;
        params['payers'] = payerData || null;
        params['serviceFee'] = state[0].serviceFee || 0
        removeLocalStorageItem('selectedDate');
        removeLocalStorageItem('selectedConsultationType');
        removeLocalStorageItem('selectedTime');

        return navigate.push(`/patient`, {
          appointmentData: { ...payloadScheduleData, ...params },
        });
      }
      return setShowModal(true);
    }
  };

  const fillProcedureData = () => {
    var result = Object.entries(selectedDay);
    const strDate =
      result[0][1].toString().length == 1
        ? '0' + result[0][1].toString()
        : result[0][1].toString();
    const strMonth =
      result[1][1].toString().length == 1
        ? '0' + result[1][1].toString()
        : result[1][1].toString();

    const selectedDate =
      strDate + '-' + strMonth + '-' + result[2][1].toString();

    if (!dateScheduleData) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tanggal Konsultasi',
      });
    } else if (dateScheduleData) {
      const payloadScheduleData = {
        schedule_id: null,
        doctor_schedule_id: null,
        consultation_type: 0,
        consultation_name: '',
        scheduleType: 0,
        note: '',
        doctor_id: 0,
        doctor_hospital_id: state[0].doctor_hospital_id,
        specialization_id: 0,
        poli_id: 0,
        doctorName: '',
        hospital_id: state[0].hospital_id,
        hospitalName: state[0].hospital_name,
        paymentMethodtype: 0,
        hospitalType: state[0].hospital_type,
        complaints: '',
        timezone: state[0].timezone,
        serviceType: state[0].serviceType,
        hospitalPayer: payerNonBPJS,
        mcuProceduresId: state[0].mcuProceduresId,
      };
      if (localStorage.accessToken) {
        let params = {};
        params['name_doctor'] = '';
        params['search_id_hospital'] = state[0].search_id_hospital?.length
          ? [state[0].search_id_hospital[0]]
          : [];
        params['search_specialization_id'] = 0;
        params['search_gender'] = state[0].search_gender || null;
        params['search_consultation_type'] = 0;
        params['hospital_group_id'] = state[0].hospital_group_id || null;
        params['doctor_imageurl'] = state[0].doctor_imageurl || null;
        params['doctor_name'] = state[0].doctor_name || null;
        params['specialization_name'] = state[0].specialization_name || null;
        params['hospital_image_url'] = state[0].hospital_image_url || null;
        params['hospital_name'] = state[0].hospital_name || null;
        params['hospital_address'] = state[0].hospital_address || null;
        params['payers'] = payerData || null;
        params['date'] = selectedDate;
        params['isPrepaid'] = state[0].isPrepaid || null;
        params['mcuProceduresId'] = state[0].mcuProceduresId || null;
        params['serviceFee'] = state[0].serviceFee || 0

        removeLocalStorageItem('selectedDate');
        removeLocalStorageItem('selectedConsultationType');
        removeLocalStorageItem('selectedTime');

        return navigate.push(`/patient`, {
          appointmentData: { ...payloadScheduleData, ...params },
        });
      }
      return setShowModal(true);
    }
  };

  const fillTimeSlot = async (e) => {
    // console.log(e);
    storeLocalStorageItem({ storageKey: 'selectedTime', storageValue: e });
    settimeSlotData(e);
  };

  const setDataPM = async () => {
    storeLocalStorageItem({
      storageKey: 'selectedTime',
      storageValue: '00:00-00:00',
    });
    settimeSlotData('00:00-00:00');
    setScheduleSlotIndex(1);
    setconsultationTypeData(3);
  };

  const getCalendarDisabled = async () => {
    const payload = {
      month: 0,
      doctor_hospital_id: state[0].doctor_hospital_id,
      hospital_id: state[0].hospital_id,
      active_date: 0,
      split_date: 1,
      year: curYear,
      serviceType: state[0].serviceType,
    };

    const results = await dispatch(getDisabledDate(payload));
    setDisabledDate(results ? results.deactiveDate : []);
    setEnabledDate(results ? results.activeDate : []);
    setMaxDate(results ? results.lastDate : []);
  };

  const getSlot = async (params) => {
    if (params != null) {
      var result = Object.entries(selectedDay);

      setScheduleSlotIndex(-1);
      settimeSlotContainer(null);
      if (state[0].serviceType !== 'PM') {
        setconsultationTypeData(null);
      }

      const strDate =
        result[0][1].toString().length == 1
          ? '0' + result[0][1].toString()
          : result[0][1].toString();
      const strMonth =
        result[1][1].toString().length == 1
          ? '0' + result[1][1].toString()
          : result[1][1].toString();

      const selectedDate =
        strDate + '-' + strMonth + '-' + result[2][1].toString();

      const slotPayload = {
        doctor_id: state[0].doctor_id,
        doctor_hospital_id: state[0].doctor_hospital_id,
        date: selectedDate,
        serviceType: state[0].serviceType,
        detail_slot: 0,
      };
      dispatch(getSlotTime(slotPayload));
      setdateScheduleData(selectedDate);
    }
  };

  useEffect(() => {
    if (doctorSchedule.doctorScheduleData.length > 0) {
      var data = doctorSchedule.doctorScheduleData;

      const result = Object.entries(
        data.reduce(
          (
            datas,
            {
              consultation_type,
              consultation_name,
              schedule_id,
              fromTime,
              endTime,
            }
          ) =>
            Object.assign(datas, {
              [consultation_type]: [
                ...(datas[consultation_type] || []),
                { consultation_name, schedule_id, fromTime, endTime },
              ],
            }),
          {}
        )
      ).map(([key, value]) => ({ consultation_type: key, children: value }));

      setMappingConsultationType(result);
    }
  }, [doctorSchedule.doctorScheduleData]);

  const getConsultationType = async (params) => {
    if (dateScheduleData == null) {
      return Swal.fire({
        icon: 'error',
        text: 'Harap Memilih Tanggal Konsultasi',
      });
    }
    const slotList = doctorSchedule.doctorScheduleData;

    settimeSlotContainer(true);
    if (slotList.length > 0) {
      const consultationIdIndex = slotList.findIndex(
        (p) => p.consultation_type == params
      );
      /* Temporary Comment 26/12/2022 
        if (slotList[consultationIdIndex].availabilty == false) {
          Swal.fire({
            icon: 'warning',
            text: `Jadwal untuk ${slotList[consultationIdIndex].consultation_name} hari ini sudah penuh, anda akan masuk ke dalam daftar tunggu jika memilih slot di hari ini`,
          });
        }
      */
      setScheduleSlotIndex(consultationIdIndex);
      setconsultationTypeData(params);
    } else {
      setScheduleSlotIndex(-1);
    }
  };

  useEffect(() => {
    getCalendarDisabled();
    dispatch(getHospitalPayer(state[0].hospital_id)).then((response) => {
      const cekBPJSPayer = response.data.payerClinics
        ? response.data.payerClinics.filter((x) => x.prefix == 'BP')
        : [];
      const cekNonBPJSPayer = response.data.payerClinics
        ? response.data.payerClinics.filter((x) => x.prefix != 'BP')
        : [];
      setPayerBPJS(cekBPJSPayer);
      setPayerNonBPJS(
        state[0]?.serviceType === 'PM'
          ? response.data.payerClinics.filter((x) => x.prefix === 'PR')
          : cekNonBPJSPayer
      );
    });
  }, []);

  useEffect(() => {
    resetRadio();
  }, [dateScheduleData]);

  useEffect(() => {
    getSlot(selectedDay);
  }, [selectedDay]);

  const onChangeCalendar = (value) => {
    storeLocalStorageItem({ storageKey: 'selectedDate', storageValue: value });
    setSelectedDay(value);
    setCheckedRdioButton(null);
    settimeSlotData(null);
    removeLocalStorageItem('selectedConsultationType');
    removeLocalStorageItem('selectedTime');
    if (state?.length > 0 && state[0]?.serviceType === 'PM') {
      setDataPM();
    }
  };

  const validateDate = (endTime) => {
    if (selectedDay) {
      const newEndTime = parseInt(endTime.replace(':', ''));
      const today = new Date();
      const { day, month, year } = selectedDay;
      const endTimeNow = `${today.getHours()}:${
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
      }`;
      const newEndTimeNow = parseInt(endTimeNow.replace(':', ''));
      return (
        formatDateAsData(today) ===
          formatDateAsData(new Date(`${year}-${month}-${day}`)) &&
        newEndTime < newEndTimeNow
      );
    }
  };

  return (
    <Layout>
      <ModalLogin
        InputForm={InputForm}
        TimerOnGoing={TimerOnGoing}
        closeModal={closeModal}
        isShow={showModal}
        phone={phone}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        requestOtp={requestOtp}
        rerequestOtp={rerequestOtp}
        setOtp={setOtp}
        validateOtp={validateOTP}
        OtpForm={OtpForm}
        SpanPhone={SpanPhone}
        setPhone={setPhone}
        timer={timer}
        message={message}
        remainingTime={remainingTime}
        setInputForm={setInputForm}
        setOtpForm={setOtpForm}
        status={status}
      />

      {/* Desktop View */}
      <div
        className="content-schedule-doctor col-12 mt-5 d-lg-block d-none"
        style={{ marginBottom: '80px' }}
      >
        <div
          className="cursor-pointer d-flex flex-row"
          style={{ width: '10%' }}
          onClick={handleDoctorSearch}
        >
          <img src={IconArrowGreen} alt="icon" />
          <p className="title-page ml-2 text-white">Kembali</p>
        </div>
        <Row>
          <div className="col-12">
            <Row className="mt-5">
              <div className="col-3">
                <div className="nav-step">
                  <button className="circle-btn-step activePage">1</button>
                  <span className="label-step">
                    <b>Jadwal</b>
                  </span>
                </div>
                <div className="nav-step">
                  <button className="circle-btn-step">2</button>
                  <span className="label-step">Data Pasien</span>
                </div>
                <div className="nav-step">
                  <button className="circle-btn-step">3</button>
                  <span className="label-step">Pembayaran</span>
                </div>
              </div>
              <div className="col-9 content-doctorSchedule-step">
                <h1>
                  {state?.length > 0 && state[0]?.serviceType === 'TL'
                    ? wordingDoctorSchedule.titleTele
                    : wordingDoctorSchedule.titleReguler}
                </h1>
                <h2>
                  Silahkan lengkapi data profil pasien untuk ke proses
                  pembayaran
                </h2>
                <div className="row">
                  <div className="col-6 pr-2">
                    <div className="app-card d-flex justify-content-center align-items-center p-3">
                      <div className="card-img mr-2">
                        <img
                          src={IconDummyAvatar}
                          width="64"
                          height="64"
                          alt="Doctor"
                        />
                      </div>
                      <div className="card-txt text-dark">
                        <b>{state?.length > 0 ? state[0]?.doctor_name : '-'}</b>
                        <br />
                        <em>
                          {state?.length > 0
                            ? state[0]?.specialization_name
                            : '-'}
                        </em>
                        {state[0].serviceType === 'TL' ||
                          (state[0].serviceType === 'PM' && (
                            <>
                              <br />
                              <em className="font-weight-bold">
                                Rp {formatRupiah(state[0]?.serviceFee) || '-'}
                              </em>
                            </>
                          ))}
                      </div>
                      {/* <div className="w-75">
                        <Button
                          styles={{ fontSize: '12px' }}
                          label="Ganti Dokter"
                          type="primary-blue"
                          icon={IconArrow}
                          iconPosition="right"
                          onClick={handleDoctorSearch}
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-6 pl-2">
                    <div className="app-card d-flex justify-content-center align-items-center p-3">
                      <div className="card-img mr-2">
                        <img
                          src={IconDummyPlace}
                          width="64"
                          height="64"
                          alt="Doctor"
                        />
                      </div>
                      <div className="card-txt text-dark">
                        <b>{state[0]?.hospital_name || '-'}</b>
                        <br />
                        {/* <em>{wordingHopitalType[2]}</em> temporary comment*/} 
                        <p className="ml-0">
                          {state[0]?.hospital_address || '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 row">
                  <div className="card-txt col-12 pt-2 pb-2">
                    <div className="pr-0 ml-0 text-dark">
                      <b>Tanggal yang dipilih</b>
                    </div>
                  </div>
                  <div className="col-md-6 pr-2">
                    <div className="app-card">
                      <div className="col-12">
                        {(state?.length > 0 &&
                          state[0]?.serviceType === 'TL') ||
                        state[0]?.serviceType === 'JT' ? (
                          <Calendar
                            value={selectedDay}
                            onChange={(value) => onChangeCalendar(value)}
                            colorPrimary="#007DFF"
                            calendarClassName="react-calendar"
                            disabledDays={disableDate}
                            minimumDate={utils().getToday()}
                            maximumDate={maxDate}
                            customDaysClassName={enabledDate}
                          />
                        ) : (
                          null
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 pl-2">
                    {scheduleSlotContainer && (
                      <div className="init-app-card ">
                        {state?.length > 0 && state[0]?.serviceType !== 'PM' ? (
                          <div className="card-txt col-12">
                            <div className="card-txt col-12 pt-2 pb-2 pl-0 ml-0 text-dark">
                              <b>Tipe Konsultasi</b>
                            </div>
                            <div>
                              <ul>
                                {mappingConsultationType.length > 0 ? (
                                  mappingConsultationType
                                    ?.filter((e) => {
                                      if (state[0]?.serviceType === 'TL') {
                                        if (+e?.consultation_type === 3) {
                                          return e;
                                        }
                                      } else {
                                        if (+e?.consultation_type !== 3) {
                                          return e;
                                        }
                                      }
                                    })
                                    ?.map((data, i) =>
                                      data.consultation_type == 1 &&
                                      payerNonBPJS.length > 0 ? (
                                        <li key={data.consultation_type}>
                                          <input
                                            type="radio"
                                            className={
                                              data.availabilty == false
                                                ? 'fullbook'
                                                : 'hidden'
                                            }
                                            name="cb"
                                            id={'cb1' + data.consultation_type}
                                            onClick={(e) =>
                                              getConsultationType(
                                                data.consultation_type
                                              )
                                            }
                                            value={data.consultation_type}
                                            onChange={handleRadioButton}
                                            checked={
                                              checkedRadioButton ==
                                              data.consultation_type
                                            }
                                          />
                                          <label
                                            htmlFor={
                                              'cb1' + data.consultation_type
                                            }
                                          >
                                            {data.children[0].consultation_name}
                                          </label>
                                        </li>
                                      ) : data.consultation_type == 2 &&
                                        payerBPJS.length > 0 ? (
                                        <li key={data.consultation_type}>
                                          <input
                                            type="radio"
                                            className={
                                              data.availabilty == false
                                                ? 'fullbook'
                                                : 'hidden'
                                            }
                                            name="cb"
                                            id={'cb1' + data.consultation_type}
                                            onClick={(e) =>
                                              getConsultationType(
                                                data.consultation_type
                                              )
                                            }
                                            value={data.consultation_type}
                                            onChange={handleRadioButton}
                                            checked={
                                              checkedRadioButton ==
                                              data.consultation_type
                                            }
                                          />
                                          <label
                                            htmlFor={
                                              'cb1' + data.consultation_type
                                            }
                                          >
                                            {data.children[0].consultation_name}
                                          </label>
                                        </li>
                                      ) : data.consultation_type == 3 ? (
                                        <li key={data.consultation_type}>
                                          <input
                                            type="radio"
                                            className={
                                              data.availabilty == false
                                                ? 'fullbook'
                                                : 'hidden'
                                            }
                                            name="cb"
                                            id={'cb1' + data.consultation_type}
                                            onClick={(e) =>
                                              getConsultationType(
                                                data.consultation_type
                                              )
                                            }
                                            value={data.consultation_type}
                                            onChange={handleRadioButton}
                                            checked={
                                              checkedRadioButton ==
                                              data.consultation_type
                                            }
                                          />
                                          <label
                                            htmlFor={
                                              'cb1' + data.consultation_type
                                            }
                                          >
                                            {data.children[0].consultation_name}
                                          </label>
                                        </li>
                                      ) : (
                                        <p
                                          className={`text-dark font14 font-italic text-center ${
                                            i ===
                                            mappingConsultationType.length - 1
                                              ? ''
                                              : 'd-none'
                                          }`}
                                        >
                                          -- Tidak ada data tipe konsultasi --
                                        </p>
                                      )
                                    )
                                ) : (
                                  <p className="text-dark font14 font-italic text-center">
                                    -- Tidak ada data tipe konsultasi --
                                  </p>
                                )}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div className="card-txt col-12">
                            <div className="card-txt col-12 pt-2 pb-2 pl-0 ml-0 text-dark">
                              <b>Jam Operasional</b>
                              <ul className='check-list '>
                                <li>Senin s/d Minggu</li>
                                <li>Jam 09:00  - 21:00</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {timeSlotContainer && (
                          <div className="card-txt col-12 mt-3">
                            <div className="card-txt col-12 pt-3 pb-3 pl-0 ml-0 text-dark">
                              <b>Pilih Waktu</b>
                            </div>
                            <div className="time-slot">
                              <ul>
                                {doctorSchedule.doctorScheduleData[
                                  scheduleSlotIndex
                                ]['slot'] ? (
                                  doctorSchedule.doctorScheduleData[
                                    scheduleSlotIndex
                                  ]['slot']
                                    ?.filter(
                                      (e) =>
                                        +e.consultation_type ===
                                        +checkedRadioButton
                                    )
                                    .map((data) => (
                                      <li key={data.time}>
                                        <input
                                          type="radio"
                                          className="hidden"
                                          name="ca"
                                          id={data.time}
                                          value={data.time}
                                          onClick={(e) =>
                                            fillTimeSlot(e.target.value)
                                          }
                                          disabled={
                                            data.full ||
                                            validateDate(
                                              data?.time?.split('-')[1]
                                            )
                                          }
                                          checked={timeSlotData == data.time}
                                        />
                                        <label htmlFor={data.time}>
                                          {data.time}
                                        </label>
                                      </li>
                                    ))
                                ) : (
                                  <div className="card-txt col-12 pt-3 pb-3">
                                    <li key="notfound">
                                      <p>
                                        <strong>Jadwal Tidak Ditemukan</strong>
                                      </p>
                                    </li>
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {initScheduleSlotContainer && (
                      <div className="init-app-card d-flex justify-content-center align-items-center">
                        <div>
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <img
                              src="https://storage.aido.id/microservices/assets/clinic/time-wait.svg"
                              width="113"
                              height="113"
                              alt="Doctor"
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <p style={{ fontWeight: '500' }}>
                              Silahkan pilih tanggal terlebih dahulu
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {state?.length > 0 && state[0]?.serviceType !== 'PM' && (
                    <div className="legend-container col-md-6 d-flex justify-content-left align-items-center">
                      <div className="legend available"></div>
                      <div className="label-legend-calendar mr-3">Tersedia</div>
                      {/* Temporary Comment # 27/12/2022
                      <div className="legend full-waiting"></div>
                      <div className="label-legend-calendar mr-3">
                        Tersedia untuk daftar tunggu
                      </div> 
                    */}
                      <div className="legend full"></div>
                      <div className="label-legend-calendar mr-3">
                        Habis Terpesan
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  styles={{ marginTop: '50px' }}
                  label="Selanjutnya"
                  onClick={handleSubmit}
                  disable={
                    !dateScheduleData || !consultationTypeData || !timeSlotData
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
      </div>

      {/* Mobile View */}
      <MobileDoctorSchedule
        state={state}
        handleDoctorSearch={handleDoctorSearch}
        selectedDay={selectedDay}
        onChangeCalendar={onChangeCalendar}
        disableDate={disableDate}
        maxDate={maxDate}
        enabledDate={enabledDate}
        scheduleSlotContainer={scheduleSlotContainer}
        doctorSchedule={doctorSchedule}
        handleRadioButton={handleRadioButton}
        checkedRadioButton={checkedRadioButton}
        getConsultationType={getConsultationType}
        payerNonBPJS={payerNonBPJS}
        payerBPJS={payerBPJS}
        timeSlotContainer={timeSlotContainer}
        fillTimeSlot={fillTimeSlot}
        initScheduleSlotContainer={initScheduleSlotContainer}
        scheduleSlotIndex={scheduleSlotIndex}
        fillScheduleData={fillScheduleData}
        storeLocalStorageItem={storeLocalStorageItem}
        timeSlotData={timeSlotData}
        mappingConsultationType={mappingConsultationType}
        validateDate={validateDate}
      />
    </Layout>
  );
};

export default DoctorSchedule;
